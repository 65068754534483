import { Component } from 'react';
import './Router.css'
import NavBar from '../NavBar/NavBar'
import { Socket } from 'socket.io-client';
import Play from '../Play/Play'
import Screen from '../Screen/Screen'

type State = {
    page_name : string
    screen : string
    style : any
    pages : string[]
}

type Props = {
    grid_size : number
    width : number
    height : number
    socket : Socket
}

export default class Router extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {
            page_name : 'Home',
            screen  : 'Play',
            style : this.get_style(),
            pages : ["Rules"]
            //pages : process.env.REACT_APP_PAGES == "FULL" ? ["Home", "Rules", "White Paper", "About Me"] :  ["Home", "Rules"] 
        }
        this.get_style.bind(this)
    }
    
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
     if (this.props.grid_size !== prevProps.grid_size){
            this.setState(
                {
                    style : this.get_style()
                }
            )
        }   
    }

    get_style(){
        const grid_size = this.props.grid_size
        const style = {gridTemplateColumns : `1fr`, gridTemplateRows : `${0.75*grid_size}px auto`}
      return style 
      }

    route_callback = (new_route : string) => {
        this.setState({
            page_name : new_route,
            screen  : new_route
        })

    }

    render() {
        console.log(process.env) 
        return <div className='base' style = {this.state.style}>
                    <div className='nav-bar-container' style = {{fontSize : `${this.props.grid_size/3.85}px`}}>
                        <NavBar left_buttons = {this.state.pages} //["Home", "Rules", "White Paper", "About Me"]
                                right_buttons = {["Play"]} 
                                callback = {this.route_callback}
                                grid_size = {this.props.grid_size * 0.75}
                                current_page = {this.state.page_name}/>
                    </div>
                    <div className='wrapper'>
                        <Screen screen_name = {this.state.screen} 
                                grid_size = {this.props.grid_size} 
                                socket = {this.props.socket}
                                width = {this.props.width}
                                height = {this.props.height}/>
                    </div>
                </div>

    }
}
import { rules_1, rules_2 , rules_3} from "../Pages/Chapters/Rules_MD"
import { nft_tokens, road_map , big_picture, marketing, play_queues, bookkeeping, daily_events} from "../Pages/Chapters/WhitePaper_MD"
import { about_me} from "../Pages/Chapters/About_MD"



export const RULES_NAMES : string [] = ["Introduction", "Quantum Analogy", "Game Rules"]
export const RULES_PICTURES : string[][] = [["rules/feynman.png", "rules/chess_earth.png"],
                                        ["rules/starting_piece.png", "rules/state_collapse.png"],
                                        ["rules/checking_capable.png", "rules/qc_art.png"]]


export const RULES_CHAPTERS : Record<string, string> = {
    "Introduction" : rules_1, 
    "Quantum Analogy" : rules_2,
    "Game Rules" : rules_3,
}


export const WP_NAMES : string [] = ["Big Picture", "Play Queues", "NFT Tokens", "Bookkeeping", "Daily Events", "Road Map", "Marketing"]
export const WP_PICTURES : string[][] = [["white_paper/gods_unchained.png", "white_paper/quantum_chess_board.png"],
                                        ["white_paper/queues.png", "white_paper/eth.png"],
                                        ["white_paper/tokens.png", "white_paper/tokens2.png"],
                                        ["white_paper/calendar.png", "white_paper/sunset.png"],
                                        ["white_paper/math.png", "white_paper/raffle.png"],
                                        ["white_paper/roadmap1.png", "white_paper/einstein.png"],
                                        ["white_paper/social.png", "white_paper/gotham.png"]]

export const WP_CHAPTERS : Record<string, string> = {
    "Big Picture" : big_picture,
    "Play Queues" : play_queues,
    "NFT Tokens" : nft_tokens,
    "Daily Events" : daily_events,
    "Bookkeeping" : bookkeeping,
    "Road Map" : road_map,
    "Marketing" : marketing
}


export const ABOUT_NAMES : string [] = ["About Me"]
export const ABOUT_PICTURES : string[][] = [["white_paper/me.png", "white_paper/multi_chess.png"]]

export const ABOUT_CHAPTERS : Record<string, string> = {
                                                "About Me" : about_me
                                            }
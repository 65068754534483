import React, { Component } from 'react';
import './ForwardBackwardArrows.css';


type State = {
    arrow_url : string, 
    gray_url : string
}


type Props = {
    callback : (move : number) => void
    new_game? : () => void
    length : number
    current_num : number
    hidden? : boolean
    divisor : number
}

const arrow_url = 'assets/arrow.png'



export default class ForwardBackArrows extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.length !== prevProps.length){
            this.forceUpdate()
       }
    }

    componentDidMount(): void {
        document.addEventListener('keydown', e => {this.keyboard(e)})
    }

    componentWillUnmount(): void {
        document.removeEventListener('keydown', this.keyboard, false)
    }
   
    keyboard(e : KeyboardEvent){
        if (e.key === 'ArrowRight'){
            this.forward()
        } else if (e.key === 'ArrowLeft'){
            this.backward()
        }
    }

    forward = () => {
        this.props.callback(1);
    }

    backward = () => {
        this.props.callback(-1);
    }

    render() {
    if (this.props.hidden){
        return null
    }
    else if (this.props.current_num === 0 && this.props.current_num === this.props.length -1) {
    return <div className='forward-back-arrows'>
                <div className='page-number'>{Math.ceil((this.props.current_num + 1)/this.props.divisor) }</div>
        </div>
        }
    else if (this.props.current_num === 0) {
        return <div className='forward-back-arrows'>
                    <div className='forward-button' style = {{backgroundImage : `url(${arrow_url})`, backgroundRepeat : 'no-repeat'}} onClick = {this.forward}></div>
                    <div className='page-number'>{Math.ceil((this.props.current_num + 1)/this.props.divisor) }</div>
                </div>
        }
    else if (this.props.current_num === this.props.length -1) {
        return <div className='forward-back-arrows'>
                    <div className='back-button' style = {{backgroundImage : `url(${arrow_url})`, backgroundRepeat : 'no-repeat'}} onClick = {this.backward}></div>
                    <div className='page-number'>{Math.ceil((this.props.current_num + 1)/this.props.divisor) }</div>
                </div>
        }
    else {
        return <div className='forward-back-arrows'>
                        <div className='forward-button' style = {{backgroundImage : `url(${arrow_url})`, backgroundRepeat : 'no-repeat'}} onClick = {this.forward}></div>
                        <div className='page-number'>{Math.ceil((this.props.current_num + 1)/this.props.divisor) }</div>
                        <div className='back-button' style = {{backgroundImage : `url(${arrow_url})`, backgroundRepeat : 'no-repeat'}} onClick = {this.backward}></div>
                    </div>
            }
        }
    }


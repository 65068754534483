import { Component } from 'react';
import SlideShow from '../../../Components/SlideShow/SlideShow'
import './HomeScreen.css'


type State = {
}

type Props = {
    grid_size : number
}


export default class HomeScreen extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {

        }
    }

    render() {
        return <div className='bottom-slide-layout'>
                    <SlideShow row = {1} column = {1}/>
                    <SlideShow row = {1} column = {2}/>
                    <SlideShow row = {1} column = {3}/>
                    <SlideShow row = {1} column = {4}/>
                </div>


    }
}
import React, { useEffect, useState } from 'react'
import useImagePreloader from '../../Hooks/Preloader'
import { RingLoader } from 'react-spinners'
import './Preload.css'



type Props = {
    loaded_jsx: JSX.Element[]
    layout : string
    images : string[]
}


export default function Preload(props: Props) {
    const { imagesPreloaded } = useImagePreloader(props.images)

    if (!imagesPreloaded) {
        return<div className = {props.layout}> 
            <div className='asset-loader'>                
                <RingLoader color="#36d7b7" size={100}/>
            </div>
        </div>
    }

    return  <div className = {props.layout}> {props.loaded_jsx} </div>
}
import { Component } from 'react';
import './DisplayWinner.css';

type State = {
}


type Props = {
    message: string | null,
    close: () => void
    play_again_callback : () => void
}




export default class DisplayWinner extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }
    close_url: string = `assets/red_X.png`
    close = () => {
        this.props.close()
        this.props.play_again_callback()
    }

    render() {
        if (this.props.message && this.props.message !== '') {
            return <div className='display-winner'>
                        <div className='winning-message'>{this.props.message}</div>
                        <div className='close-button' style={{ backgroundImage: `url(${this.close_url})` }} onClick={this.close}></div>
                    </div>
        } else {
            return <></>
        }
    }
}
export const rules_1 : string = `# Introduction

> *We can imagine that this complicated array of moving things which constitutes “the world” is something like a great chess game being played by the gods, and we are observers of the game. We do not know what the rules of the game are; all we are allowed to do is to watch the playing.  Of course, if we watch long enough, we may eventually catch on to a few of the rules. The rules of the game are what we mean by fundamental physics…*
    
> **-** Richard Feynman

And what Richard Feynman means by fundamental physics, popular culture calls quantum mechanics. Quantum chess inverts Feynamn's analogy and allows you to a be the god playing with quantum mechanical chess set. 
Analogies aside, the game of quantum chess is a semi-educational, semi-serious, and extremely fun exploration into the space where 
the lines between chess and quantum mechanics become blurred. 

The initial rules of the game were thought up by a group of physics graduate students trying to rigorously apply their knowledge of quantum 
mechanics to the game of chess.  Within the rules of quantum chess you will find the principles of eigenstates, superposition, conservation laws, 
state-function collapse(via strong and weak measurement), and quantum entaglement, applied analogously through the medium of a chess set. 
 

The key concepts of quantum chess are presented in two different ways:

1.  The quantum analogy way, where the connection between the game and quantum mechanics is placed at the forefront of the description. 
2.  The game rules way, where the rules are presented in a traditional 'board game rules' style. 

So, if you just want to read the rules skip ahead to the **Game Rules** section, although it will be easier to understand using the quantum mechanics analogies.

*Or, on the other hand if you'd prefer to follow in the footsteps of Dr. Feynman just play a few games and learn as you go.*
`
export const rules_2 : string = `# Quantum Analogies

## Blurring the Lines Between Chess and Quantum Mechanics

### Quantum Chess Eigenstates

In nature, quantum particles have what are known as eigenstates, which among other things are the values that can be observed when the particle is measured.
The eigenstates for our chess pieces are the 6 normal chess pieces, King, Queen, Rook, Bishop, Night, and Pawn. When a piece is captured it is measured
and a specific chess piece eigenstate must be observed.  In quantum mechanics what exactly happens during the moment of measurement is an open question.  
While we don't know exactly how measurement happens we have theories that can predict the distribution of results and even state of the art modeling techniques 
will use 'random' numbers to mimic nature.  In quantum chess we don't have any randomness and the observed eigenstate is chosen by the player whose piece was captured.

### Quantum Chess Superposition

One of the remarkable results of quantum mechanics is that particles can often times be in a superposition of eigenstates. This is just the mathematical way to say 
communicate the popular interpretation of quantum mechanics that states that a particle can be multiple things at once. This phenomena is famously at the root of Schroedinger's 
simultaneously dead and alive cat. 

In Quantum Chess we start with all of our pieces in a super position of the eigenstates (King, Queen, Rook, Bishop, Night, and Pawn), and is represented by
the image on the right.  Each piece can be conceptualized as having a 1/2 chance to be a Pawn, a 1/8 chance to be a Rook, Bishop or Night, and a 1/16 chance 
of being a King or Queen, that way the sum of all 16 pieces gives the usual totals for a normal chess set. 

As moves are made the superposition of each piece is effected in a predictable way.

### Quantum Conserved Quantities

One of the keys to understanding quantum mechanical interactions is studying the conserved quantities which will remain unchanged in any given
system. In quantum mechanics some examples of conserved quantities are angular momentum, charge, and particle parity.  In quantum chess each set of 
pieces has exactly 1 King, 1 Queen, 2 Rooks, 2 Bishops, 2 Nights, and 8 Pawns.  

Occasionally, in physics, unexpected results can show that a quantity that was thought to be conserved is only conserved most of the time. 
For example, the conservation of parity was observed to be broken by a particular particle decay via the weak force in 1957. While such 
decays are quite rare, they do occur, which, makes the quantity in question 'nearly conserved' rather than fully conserved.

It turns out rare events in quantum chess can also turn what was once thought to be a conserved quantity into merely a nearly conserved quantity. 
For example, when a piece that could be a Pawn makes it to the other side of the board it turns into a superpositiion of a Queen, Rook, Bishop, and Night, 
and changes the total number of Queens/Rooks/Bishops/Nights increases by 1 while the total number of pawns decreases by 1.    

The true conservation law turns out to be that each side has 16 total pieces (captured + uncaptured).

### State Collapse via Strong and Weak Measurement

There are two possible types of measurements that can be made on a quantum system. The first is a strong measurement which collapses the system into 
a single pure eigenstate. The second is a weak measurement where a few eigenstates have no chance of being measured but a few still remain.  In quantum 
chess we have both of these types of measurements as well.

#### Weak Measurement collapse examples
1.  When a piece moves in a way that some of its possible pieces can't, those pieces are removed from its state.
2.  When a piece that could be a King remains in check after a move, it can no longer be a King and the King is removed from its state.  

#### Strong Measurement collapse examples
1.  When a piece of yours is taken it is measured, you choose which piece it fully collapses into.
2.  When a piece of yours moves in a way that only one of its possibilities can move, the piece fully collapses into that piece.

### Quantum Chess Entanglement 

When two particles are entangled the measurement or change of the state of one particle affects the state of the particles that it is entangled with. 
In quantum chess all the pieces of a given color are fully entangled.  Thus, any change to a single, piece will have an effect on all of your other pieces.
Two examples of this happening are:
 
1.  One of your pieces fully collapsing into a Queen will remove the 'Queen-ness' from your other pieces. 

2.  You have 3 remaining pieces that could be Nights.  You move one of those pieces straight which eliminates its 'Night-ness'.  This causes your other 
    2 remaining could be Nights to fully collapse because you must have at least 2 Nights.  This can cause a chain reaction leading to more pieces collapsing.
`

export const rules_3 : string = `# Game Rules

### Pieces

Every piece is made up of a set of normal chess pieces. At the beginning of the game each piece is made up of the entire set of chess pieces, i.e. a 
King, Queen, Rook, Bishop, Night, or Pawn.  In short we would call this piece a **KQRBNP**.  



### Conserved Quantities

Each side has a starting total of 1 King, 1 Queen, 2 Rooks, 2 Bishops, 2 Nights, and 8 Pawns.  After each move, a recursive series of 
deductions and inductions guarantees that your set of pieces that can be reduced into exactly that set of pieces.  When a piece that has a **P** in 
its set of pieces reaches the other side of the board it changes the piece totals for its team.  Specifically, it makes the number of **P** decrease by 1 
while the total of **Q** + **R** + **N** + **B** is increased by 1.

### Checking 

In normal chess you are not allowed to leave a King in check for your opponent to capture.  In quantum chess, once a piece has 2 or fewer pieces in its 
set of pieces it becomes a 'checking capable' piece.  After your move, any of your pieces that could be taken by one of your opponents 'checking capable' 
pieces has *K* removed from its subset of pieces.  It is illegal to make a move that would remove every possible King you have. 

Likewise, it is illegal to make a move that would leave you with a single possible King (collapsing that piece's set of normal chess pieces to just **K**) 
where your king could be captured by any opponents piece.  If you are left with no legal moves, you lose the game.  There is no stalemate.

Pieces that are 'checking capable' will have a target icon on them an example of such a piece can be seen on the right. 

### Piece Movement (*Your Turn*)
1. Each piece can move like any piece that is part of its subset of pieces.
2. After a piece moves it *collapses*  into the set of pieces that could have possibly made that move.
3. If a piece that could be a pawn reaches the last rank it becomes a **QRBN**, and the total amount of Pawns would decrease by 1 while the total amount of 
**Q** + **R** + **N** + **B** is increased by 1.
4. Each enemy piece with 2 or fewer subpieces *checks* your pieces, and removes Kings from any piece in check.
5. If a piece was taken, the player whose piece got taken selects which piece it was.
5. Both players recursively collapse their pieces (via weak and strong collapse) so that all conserved quantities can be satisfied.
6. If a player is in checkmate (including stalemate) the game ends.
7. If a player has 0 seconds on their clock the game ends.

### Game Over
In Quantum Chess there are no ties: 

Stalemate is the equivalent of checkmate. A player can win in one of the following 4 ways:

1. Checkmate. (Including stalemate)
2. On time. (Opposing player runs out of time)
3. Black wins if the game exceeds 100 moves. (This is meant to offset the first move advantage)


### Odds and Ends 

#### Pawn Moves 
In normal chess a Pawn can move 2 spaces on its first move.  In Quantum Chess a Pawn can move forward 2 spaces if it is in the first or second rank.
This could be the rule for normal chess and we would never know.

#### Defining Checkmate 
Checkmate is defined by having no remaining moves that would not allow your ooponent to eliminate all of your potential Kings on the next move.  It is 
illegal to make a move that would result in leaving **all** of your potential Kings in check.  And you can not leave a fully defined King on a square that 
your opponent could capture on the next move.

#### The Clock 

The time control is written as 2 numbers separated by a vertical bar, a time control of ' 1 | 2 ' would mean your clock starts with 1 minute on it, 
you get a 2 second increment added to your time after every move.

The signature time control for quantum chess is ' 3  3 '.


#### Castling and En Passant...
Are an artifact of the macroscopic world and are not featured in our quantum mechanical universe.


#### Always remember ...
If you are confused why you can not make a move, be sure to check the game state messages which appear above the side board.

## *Potential Rule Changes* 

1. Use RNG to determine piece upon capture. A **QRP** would have 1/11 chance **Q**, 2/11 chance **R** and 8/11 chance **P**. To start the game odds would change as pieces are 
captured.

2. Add time evolution where if a piece has not moved in a certain amount of time (either moves or clock time) it regains some of its superpositions.

3. Add en passant. People love en passant.

`

import './PieceImage.css'

interface Props {
    image_url : string;
    num : number;
}



export default function PieceImage({image_url, num}: Props){
            return  <div 
                    style = {{backgroundImage : `url(${image_url})`, 
                            gridColumnStart : `${(num % 5) + 2}`, gridColumnEnd : `${(num % 5) + 3}`,
                            gridRowStart : `${Math.floor(num/5) + 2}`, gridRowEnd : `${Math.floor(num / 5) + 3}`}}
                    className = 'piece-image'>
                    </div>

}
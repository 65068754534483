import { Component } from 'react';
import { Socket } from 'socket.io-client';
import {play} from '../../../Constants/buttonStyles'
import Button from '../../../Components/Button/Button';
import './QueueButton.css'

type State = {
    dummy_string : string
}

type Props = {
    socket : Socket
    grid_size : number
    callback : any 
    game_ready : boolean | null
}


export default class QueueButton extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {
            dummy_string : ''
        }
    }   

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.game_ready !== prevProps.game_ready){
            this.forceUpdate()
        }
    }

    render() {
        if (this.props.game_ready === null){
            return  <Button name = 'Play' handle_click = {this.props.callback} key={1} style = {play} class_name = 'queue-button'/>
          } 
          else if (this.props.game_ready === false)
            return <div className='waiting'>
                      <div className='waiting-text' style = {{fontSize : this.props.grid_size/5.5}}>Waiting for opponent . . . </div>
                    </div>
            else {
                return <div></div>
            }
    }
}
import React, { Component } from 'react';
import { interval } from 'rxjs';
import './WordShow.css'


type State = {
    word_index : number
    intervalId? : NodeJS.Timer
    top : number
    left : number
}

type Props = {
    interval? : number
    words : string []
}


export default class WordShow extends React.Component <Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {
            word_index : 0,
            top : 1,
            left : 1
        }
    }
  
    componentDidMount() {
      const newIntervalId = setInterval(() => {
        this.setState(prevState => {
          return {
            ...prevState,
            word_index: this.get_new_word_index(),
          };
        });
      }, 500);
      this.setState(prevState => {
        return {
          ...prevState,
          intervalId: newIntervalId,
        };
      });
    
    }
  
    componentWillUnmount(){
      clearInterval(this.state.intervalId);
    }
  
    get_new_word_index(){
    
      return ((this.state.word_index + 1) % this.props.words.length)
    }
    render() {
      return (
        <div className='wordshow'>{this.props.words[this.state.word_index]}</div>
      );
    }
  }
  
//   style = {{top : `${this.state.top}%`, left : `${this.state.left}%`}}
export const ALL_PICTURES : string[] = ['wrinkle_brain.png', 'red_X.png',
                                    'black_pieces/spf/bn.png', 'black_pieces/spf/kqrnp.png', 'black_pieces/spf/kp.png', 'black_pieces/spf/qrn.png', 
                                    'black_pieces/spf/krbp.png', 'black_pieces/spf/kqr.png', 'black_pieces/spf/kb.png', 'black_pieces/spf/np.png', 
                                    'black_pieces/spf/qp.png', 'black_pieces/spf/krn.png', 'black_pieces/spf/rnp.png', 'black_pieces/spf/krp.png', 
                                    'black_pieces/spf/kqrbn.png', 'black_pieces/spf/rn.png', 'black_pieces/spf/kbp.png', 'black_pieces/spf/qbn.png', 
                                    'black_pieces/spf/qrbp.png', 'black_pieces/spf/kqrbp.png', 'black_pieces/spf/kqbp.png', 'black_pieces/spf/kr.png', 
                                    'black_pieces/spf/krb.png', 'black_pieces/spf/qrp.png', 'black_pieces/spf/rbn.png', 'black_pieces/spf/krnp.png', 
                                    'black_pieces/spf/krbnp.png', 'black_pieces/spf/rp.png', 'black_pieces/spf/qbp.png', 'black_pieces/spf/bp.png', 
                                    'black_pieces/spf/qr.png', 'black_pieces/spf/rbnp.png', 'black_pieces/spf/kqp.png', 'black_pieces/spf/qnp.png', 
                                    'black_pieces/spf/kqrb.png', 'black_pieces/spf/qrbn.png', 'black_pieces/spf/qbnp.png', 'black_pieces/spf/rb.png', 
                                    'black_pieces/spf/kbnp.png', 'black_pieces/spf/kqbn.png', 'black_pieces/spf/kqn.png', 'black_pieces/spf/knp.png', 
                                    'black_pieces/spf/kqrp.png', 'black_pieces/spf/kqrbnp.png', 'black_pieces/spf/qrb.png', 'black_pieces/spf/kqnp.png', 
                                    'black_pieces/spf/rbp.png', 'black_pieces/spf/kqb.png', 'black_pieces/spf/qb.png', 'black_pieces/spf/qrbnp.png', 
                                    'black_pieces/spf/krbn.png', 'black_pieces/spf/qrnp.png', 'black_pieces/spf/kq.png', 'black_pieces/spf/kbn.png', 
                                    'black_pieces/spf/kqbnp.png', 'black_pieces/spf/bnp.png', 'black_pieces/spf/qn.png', 'black_pieces/spf/kqrn.png', 
                                    'black_pieces/spf/kn.png', 'black_pieces/solo/default/n.png', 'black_pieces/solo/default/q.png', 
                                    'black_pieces/solo/default/p.png', 'black_pieces/solo/default/r.png', 'black_pieces/solo/default/b.png', 
                                    'black_pieces/solo/default/k.png', 'black_pieces/sp/bn.png', 'black_pieces/sp/kqrnp.png', 'black_pieces/sp/kp.png', 
                                    'black_pieces/sp/qrn.png', 'black_pieces/sp/krbp.png', 'black_pieces/sp/kqr.png', 'black_pieces/sp/kb.png', 
                                    'black_pieces/sp/np.png', 'black_pieces/sp/qp.png', 'black_pieces/sp/krn.png', 'black_pieces/sp/rnp.png', 
                                    'black_pieces/sp/krp.png', 'black_pieces/sp/kqrbn.png', 'black_pieces/sp/rn.png', 'black_pieces/sp/kbp.png', 
                                    'black_pieces/sp/qbn.png', 'black_pieces/sp/qrbp.png', 'black_pieces/sp/kqrbp.png', 'black_pieces/sp/kqbp.png', 
                                    'black_pieces/sp/kr.png', 'black_pieces/sp/krb.png', 'black_pieces/sp/qrp.png', 'black_pieces/sp/rbn.png', 
                                    'black_pieces/sp/krnp.png', 'black_pieces/sp/krbnp.png', 'black_pieces/sp/rp.png', 'black_pieces/sp/qbp.png', 
                                    'black_pieces/sp/bp.png', 'black_pieces/sp/qr.png', 'black_pieces/sp/rbnp.png', 'black_pieces/sp/kqp.png', 
                                    'black_pieces/sp/qnp.png', 'black_pieces/sp/kqrb.png', 'black_pieces/sp/qrbn.png', 'black_pieces/sp/qbnp.png', 
                                    'black_pieces/sp/rb.png', 'black_pieces/sp/kbnp.png', 'black_pieces/sp/kqbn.png', 'black_pieces/sp/kqn.png', 
                                    'black_pieces/sp/knp.png', 'black_pieces/sp/kqrp.png', 'black_pieces/sp/kqrbnp.png', 'black_pieces/sp/qrb.png', 
                                    'black_pieces/sp/kqnp.png', 'black_pieces/sp/rbp.png', 'black_pieces/sp/kqb.png', 'black_pieces/sp/qb.png', 
                                    'black_pieces/sp/qrbnp.png', 'black_pieces/sp/krbn.png', 'black_pieces/sp/qrnp.png', 'black_pieces/sp/kq.png', 
                                    'black_pieces/sp/kbn.png', 'black_pieces/sp/kqbnp.png', 'black_pieces/sp/bnp.png', 'black_pieces/sp/qn.png', 
                                    'black_pieces/sp/kqrn.png', 'black_pieces/sp/kn.png', 'white_pieces/spf/bn.png', 'white_pieces/spf/kqrnp.png', 
                                    'white_pieces/spf/kp.png', 'white_pieces/spf/qrn.png', 'white_pieces/spf/krbp.png', 'white_pieces/spf/kqr.png', 
                                    'white_pieces/spf/kb.png', 'white_pieces/spf/np.png', 'white_pieces/spf/qp.png', 'white_pieces/spf/krn.png', 
                                    'white_pieces/spf/rnp.png', 'white_pieces/spf/krp.png', 'white_pieces/spf/kqrbn.png', 'white_pieces/spf/rn.png', 
                                    'white_pieces/spf/kbp.png', 'white_pieces/spf/qbn.png', 'white_pieces/spf/qrbp.png', 'white_pieces/spf/kqrbp.png', 
                                    'white_pieces/spf/kqbp.png', 'white_pieces/spf/kr.png', 'white_pieces/spf/krb.png', 'white_pieces/spf/qrp.png', 
                                    'white_pieces/spf/rbn.png', 'white_pieces/spf/krnp.png', 'white_pieces/spf/krbnp.png', 'white_pieces/spf/rp.png', 
                                    'white_pieces/spf/qbp.png', 'white_pieces/spf/bp.png', 'white_pieces/spf/qr.png', 'white_pieces/spf/rbnp.png', 
                                    'white_pieces/spf/kqp.png', 'white_pieces/spf/qnp.png', 'white_pieces/spf/kqrb.png', 'white_pieces/spf/qrbn.png', 
                                    'white_pieces/spf/qbnp.png', 'white_pieces/spf/rb.png', 'white_pieces/spf/kbnp.png', 'white_pieces/spf/kqbn.png', 
                                    'white_pieces/spf/kqn.png', 'white_pieces/spf/knp.png', 'white_pieces/spf/kqrp.png', 'white_pieces/spf/kqrbnp.png', 
                                    'white_pieces/spf/qrb.png', 'white_pieces/spf/kqnp.png', 'white_pieces/spf/rbp.png', 'white_pieces/spf/kqb.png', 
                                    'white_pieces/spf/qb.png', 'white_pieces/spf/qrbnp.png', 'white_pieces/spf/krbn.png', 'white_pieces/spf/qrnp.png', 
                                    'white_pieces/spf/kq.png', 'white_pieces/spf/kbn.png', 'white_pieces/spf/kqbnp.png', 'white_pieces/spf/bnp.png', 
                                    'white_pieces/spf/qn.png', 'white_pieces/spf/kqrn.png', 'white_pieces/spf/kn.png', 'white_pieces/solo/default/n.png', 
                                    'white_pieces/solo/default/q.png', 'white_pieces/solo/default/p.png', 'white_pieces/solo/default/r.png', 
                                    'white_pieces/solo/default/b.png', 'white_pieces/solo/default/k.png', 'white_pieces/sp/bn.png', 
                                    'white_pieces/sp/kqrnp.png', 'white_pieces/sp/kp.png', 'white_pieces/sp/qrn.png', 'white_pieces/sp/krbp.png', 
                                    'white_pieces/sp/kqr.png', 'white_pieces/sp/kb.png', 'white_pieces/sp/np.png', 'white_pieces/sp/qp.png', 
                                    'white_pieces/sp/krn.png', 'white_pieces/sp/rnp.png', 'white_pieces/sp/krp.png', 'white_pieces/sp/kqrbn.png', 
                                    'white_pieces/sp/rn.png', 'white_pieces/sp/kbp.png', 'white_pieces/sp/qbn.png', 'white_pieces/sp/qrbp.png', 
                                    'white_pieces/sp/kqrbp.png', 'white_pieces/sp/kqbp.png', 'white_pieces/sp/kr.png', 'white_pieces/sp/krb.png', 
                                    'white_pieces/sp/qrp.png', 'white_pieces/sp/rbn.png', 'white_pieces/sp/krnp.png', 'white_pieces/sp/krbnp.png', 
                                    'white_pieces/sp/rp.png', 'white_pieces/sp/qbp.png', 'white_pieces/sp/bp.png', 'white_pieces/sp/qr.png', 
                                    'white_pieces/sp/rbnp.png', 'white_pieces/sp/kqp.png', 'white_pieces/sp/qnp.png', 'white_pieces/sp/kqrb.png', 
                                    'white_pieces/sp/qrbn.png', 'white_pieces/sp/qbnp.png', 'white_pieces/sp/rb.png', 'white_pieces/sp/kbnp.png', 
                                    'white_pieces/sp/kqbn.png', 'white_pieces/sp/kqn.png', 'white_pieces/sp/knp.png', 'white_pieces/sp/kqrp.png', 
                                    'white_pieces/sp/kqrbnp.png', 'white_pieces/sp/qrb.png', 'white_pieces/sp/kqnp.png', 'white_pieces/sp/rbp.png', 
                                    'white_pieces/sp/kqb.png', 'white_pieces/sp/qb.png', 'white_pieces/sp/qrbnp.png', 'white_pieces/sp/krbn.png', 
                                    'white_pieces/sp/qrnp.png', 'white_pieces/sp/kq.png', 'white_pieces/sp/kbn.png', 'white_pieces/sp/kqbnp.png', 
                                    'white_pieces/sp/bnp.png', 'white_pieces/sp/qn.png', 'white_pieces/sp/kqrn.png', 'white_pieces/sp/kn.png','white_pieces/solo/check/n.png', 
                                    'white_pieces/solo/check/q.png', 'white_pieces/solo/check/p.png', 'white_pieces/solo/check/r.png', 
                                    'white_pieces/solo/check/b.png', 'white_pieces/solo/check/k.png', 'black_pieces/solo/check/n.png', 
                                    'black_pieces/solo/check/q.png', 'black_pieces/solo/check/p.png', 'black_pieces/solo/check/r.png', 
                                    'black_pieces/solo/check/b.png', 'black_pieces/solo/check/k.png']

export const STARTING_PICTURES = ['white_pieces/sp/kqrbnp.png', 'black_pieces/sp/kqrbnp.png', 'arrow.png']


export const SOME_PICTURES = ['wrinkle_brain.png', 'red_X.png',
                            'black_pieces/solo/default/k.png', 'black_pieces/sp/bn.png', 'black_pieces/sp/kqrnp.png', 'black_pieces/sp/kp.png', 
                            'black_pieces/sp/qrn.png', 'black_pieces/sp/krbp.png', 'black_pieces/sp/kqr.png', 'black_pieces/sp/kb.png', 
                            'black_pieces/sp/np.png', 'black_pieces/sp/qp.png', 'black_pieces/sp/krn.png', 'black_pieces/sp/rnp.png', 
                            'black_pieces/sp/krp.png', 'black_pieces/sp/kqrbn.png', 'black_pieces/sp/rn.png', 'black_pieces/sp/kbp.png', 
                            'black_pieces/sp/qbn.png', 'black_pieces/sp/qrbp.png', 'black_pieces/sp/kqrbp.png', 'black_pieces/sp/kqbp.png', 
                            'black_pieces/sp/kr.png', 'black_pieces/sp/krb.png', 'black_pieces/sp/qrp.png', 'black_pieces/sp/rbn.png', 
                            'black_pieces/sp/krnp.png', 'black_pieces/sp/krbnp.png', 'black_pieces/sp/rp.png', 'black_pieces/sp/qbp.png', 
                            'black_pieces/sp/bp.png', 'black_pieces/sp/qr.png', 'black_pieces/sp/rbnp.png', 'black_pieces/sp/kqp.png', 
                            'black_pieces/sp/qnp.png', 'black_pieces/sp/kqrb.png', 'black_pieces/sp/qrbn.png', 'black_pieces/sp/qbnp.png', 
                            'black_pieces/sp/rb.png', 'black_pieces/sp/kbnp.png', 'black_pieces/sp/kqbn.png', 'black_pieces/sp/kqn.png', 
                            'black_pieces/sp/knp.png', 'black_pieces/sp/kqrp.png', 'black_pieces/sp/kqrbnp.png', 'black_pieces/sp/qrb.png', 
                            'black_pieces/sp/kqnp.png', 'black_pieces/sp/rbp.png', 'black_pieces/sp/kqb.png', 'black_pieces/sp/qb.png', 
                            'black_pieces/sp/qrbnp.png', 'black_pieces/sp/krbn.png', 'black_pieces/sp/qrnp.png', 'black_pieces/sp/kq.png', 
                            'black_pieces/sp/kbn.png', 'black_pieces/sp/kqbnp.png', 'black_pieces/sp/bnp.png', 'black_pieces/sp/qn.png', 
                            'black_pieces/sp/kqrn.png', 'black_pieces/sp/kn.png',
                            'white_pieces/sp/kqrnp.png', 'white_pieces/sp/kp.png', 'white_pieces/sp/qrn.png', 'white_pieces/sp/krbp.png', 
                            'white_pieces/sp/kqr.png', 'white_pieces/sp/kb.png', 'white_pieces/sp/np.png', 'white_pieces/sp/qp.png', 
                            'white_pieces/sp/krn.png', 'white_pieces/sp/rnp.png', 'white_pieces/sp/krp.png', 'white_pieces/sp/kqrbn.png', 
                            'white_pieces/sp/rn.png', 'white_pieces/sp/kbp.png', 'white_pieces/sp/qbn.png', 'white_pieces/sp/qrbp.png', 
                            'white_pieces/sp/kqrbp.png', 'white_pieces/sp/kqbp.png', 'white_pieces/sp/kr.png', 'white_pieces/sp/krb.png', 
                            'white_pieces/sp/qrp.png', 'white_pieces/sp/rbn.png', 'white_pieces/sp/krnp.png', 'white_pieces/sp/krbnp.png', 
                            'white_pieces/sp/rp.png', 'white_pieces/sp/qbp.png', 'white_pieces/sp/bp.png', 'white_pieces/sp/qr.png', 
                            'white_pieces/sp/rbnp.png', 'white_pieces/sp/kqp.png', 'white_pieces/sp/qnp.png', 'white_pieces/sp/kqrb.png', 
                            'white_pieces/sp/qrbn.png', 'white_pieces/sp/qbnp.png', 'white_pieces/sp/rb.png', 'white_pieces/sp/kbnp.png', 
                            'white_pieces/sp/kqbn.png', 'white_pieces/sp/kqn.png', 'white_pieces/sp/knp.png', 'white_pieces/sp/kqrp.png', 
                            'white_pieces/sp/kqrbnp.png', 'white_pieces/sp/qrb.png', 'white_pieces/sp/kqnp.png', 'white_pieces/sp/rbp.png', 
                            'white_pieces/sp/kqb.png', 'white_pieces/sp/qb.png', 'white_pieces/sp/qrbnp.png', 'white_pieces/sp/krbn.png', 
                            'white_pieces/sp/qrnp.png', 'white_pieces/sp/kq.png', 'white_pieces/sp/kbn.png', 'white_pieces/sp/kqbnp.png', 
                            'white_pieces/sp/bnp.png', 'white_pieces/sp/kqrn.png'
]

export const EXTRA = ['wrinkle_brain.png', 'red_X.png'] 
export const big_picture: string = `# Big Picture

## The Plan 

The plan is to release quantum chess as a play to earn game that leverages a layer 2 solution of the Ethereum blockchain (IMX) to trade NFTs (ERC-721) for reduced transaction costs.
The game aims to create value for all involved from the most casual player to the hardcore pre-alpha early investor. We will achieve this goal through a creative use of 4 different NFT tokens 
that create player incentives to join, play, and stay.  This will lead to a thriving community where money making opportunities exist for players of all inclinations and skill levels.

The money making strategies include, but are not limited to:

1. Winning games in the premium queue 
2. Winning games in the free to play queue 
3. Participating in the ownership of the game, and earning from the 'Daily Pot'
4. Speculating on the NFTs

The Quantum Chess Project will make it's revenue through games played in the premium queue (see **Play Queues**) at a rate of $0.20 per game.

For a more realistic estimate of number of premium games look no further than Gods Unchained.  Gods Unchained is
a NFT variant of the popular trading card game genre (See Magic the Gathering/Hearthstone). It is definitely a pay to win game and is basically pay to enjoy.  A Gods Unchained player has a chance to earn money if 
they are lucky opening packs and/or can earn a small amount of money by grinding out games continually.  Quantum chess is a NFT variant of a popular game (chess)
where players can earn money through skillfully winning games that are pay to play or earn a small amount of money by grinding out games in the free to play mode.
The analogy writes itself and therefore marks an acheivable goal to shoot for.  

The shorter game times (10-15 min/game compared to 20-30 min/game), more player friendly economics, and obvious viral 
potential should increase the chances at reaching the same number of daily games as Gods Unchained. There are an estimated 200,000 daily games played on Gods Unchained, that same amount of 
daily premium games would generate $14.6 million annually.  For reference Gods Unchained has made $58 million  in the last 6 months so the money is out there and 
the players are willing to pay (see right side bar, clicking on the image enlarges it). 

Additionally, chess is currently going through a huge popularity spike with chess.com reporting a doubling off its users in the week of Jan. 17th through Jan. 24th 2023 and is 
boasting over 20 million games played daily.


`

export const play_queues : string = ` # Play Queues

Quantum chess will launch with three different play queues, each designed for a specific type of player.  Players choose a queue that they want to play in 
and are matched against somebody else in the same queue.  Since this section will have the first references to the Quantum Chess NFTs, it seems prudent to
list their (admittedly horrible working) names so that they are not completely coming out of the blue.  Without further ado, four NFTs are :   

1. Premium Game Entry NFT
2. Utility NFT 
3. Quantum Chess Board NFT 
4. Raffle Ticket NFT

**NOTE :**  In this section values are expressed in dollars although in actuality all transactions will be in ETH executed on a layer-2. 
If you are confused by how this will work exactly please read the **Bookkeeping** section.  

## (1)  The Premium Queue

The premium queue is the money making queue and is essential to the health of the overall ecosystem.  To play in this queue you need 
a Premium Game Entry NFT.  The winner of games in this queue takes the losers Premium Game Entry NFT.  The Quantum Chess Project sells 
Premium Game Entry NFT for $5.00  and will gaurantee to buy them back at a a price of $4.50.  Therefore, each premium game NFT token sold will 
give the Quantum Chess Project $0.50 to bring value to the investors and to the players in other forms.  25% of this profit will go to the loser 
of the game in the premium queue (In the form of a Utility NFT), 25% will fund the Daily Pot (which pays out player owners), 25% funds the value 
given to the play to earn players, 20% goes to investors in Quantum Chess, and 5% goes to covering server costs and transaction fees.

Additionally, players in this queue will recieve a Quantum Chess Board NFT as they play games, with wins counting for more towards that goal.  For example, 10 
premium game wins earn an Quantum Chess Board NFT or 25 total premium games played would also earn you a Quantum Chess Board NFT.  The Quantum Chess Board NFT 
is the way for players to own the game in a sense and allows them to be eligible for the Daily Pot.


## (2) The Play To Earn Queue

The play to earn queue is free to play once you have a wallet with a layer-2 activated.  The winner of games in this queue will recieve a Raffle Ticket NFT
and the loser recieves nothing.  Once per day Utility NFTs are exchanged at a 1 : 1 rate for Raffle Tokens where the total amount of Utility NFTs given out 
is not more than can be backed up through the funds created via the premium game queue.  Each time a raffle ticket is not selected it becomes more likely to 
be selected in the next end of day raffle. 
making a synthe

## (3) The Quick Play Queue

This queue is used to practice and play the game for fun.  No ETH wallet is needed to play games in this queue.  Its primary purpose is to practice, play friends, 
and generally have a good time.   

`
export const bookkeeping : string = ` # Bookkeeping

## The Problem 

The astute reader might have noticed that there will be some difficulty with making transactions in ETH and keeping the playing field level. Is it fair for somebody 
who payed 0.003 ETH for their Premium Game Entry NFT to play somebody who payed 0.001 ETH for theirs?  Probably not.  This and problems like this are what are discussed 
in this section. 

## The Solution 

Each month we will perform bookkeeping, that will include the following steps:

### (1)  Reset the scale

Set a new buying and selling points for each NFT based on the current price of ETH.  The prices will aim to maintian the USD value of each and will be the 
price for the entire month

### (2) Sunset The Exisiting Tokens

Each token will no longer serve any purpose outside of counting towards a players stake in the Daily Pot. Sunset tokens will be worth X times their original value 
in this regard, where X is the amount of months that they have been sunset for. There will be a setting that allows players to automatically sell all their tokens 
before the sunsetting occurs at the rate that the Quantum Chess Project buys them.  Any tokens that are sunset are direct revenue for the Quantum Chess Project as 
they are effectively 'off the books'.  The revenue from this process will be distrubuted as 33% to the play to earn players (via Daily Raffle), 33% towards the 
Daily Pot (distributed evenly over the next month), 33% to the investors in the quantum chess project. 

**NOTE**: This contribution was not accounted for in the front page revenue projections as it is too hard to forecast.

## Technical Detail

Practically speaking, instead of changing properties of the NFTs, each NFT will be timestamped to allow Quantum Chess to treat each NFT correctly.


`

export const nft_tokens : string = `# NFT Tokens

Each of the 4 NFTs has its own set of purposes. While all NFTs will be able to be sold and bought on the open market, the Quantum Chess Project will
buy and sell certain NFTs for a set floor and ceiling. Once again although the values are in USD the transactions will 
all occur in ETH.  If you are confused by how this will work exactly please read the **Bookkeeping** section.  

**NOTE :** There are effects that happen when a token is '*sunset*' this may happen at the end of each month. For more information about that see the 
**Bookkeeping** section.  Also, these tokens often times refer to the 'Daily Pot' or the 'Daily Raffle' which are both explained in the 'Daily Events' section.

## Premium Game Entry NFT

**Sell Price** : $5.00  
**Buy Price** : $4.50  
**Other Ways To Earn** : Win a game in the premium queue.  
**Use(s)** : Gains entry into premium queue games.  
**Sunset Effects** : Counts as 21 × (Months Sunset) as part of 'sunset tokens' portion in the calculation of a players stake in the Daily Pot.  


## Utility NFT

**Sell Price** : $0.30  
**Buy Price** : $0.25  
**Other Ways To Earn**: Lose a game in the premium queue.  Win with a Raffle Ticket NFT.    
**Use(s)** : Levels up Quantum Chess Board NFT.  
**Sunset Effects** : Counts as 1 × (Months Sunset) as part of 'sunset tokens' portion in the calculation of a players stake in the Daily Pot.    

## Quantum Chess Board NFT

**Sell Price** : $15.00  
**Buy Price** : N/A  
**Other Ways To Earn**: Win 10 games in the premium queue.  Play 25 games in the premium queue.    
**Use(s)** : Uses your Quantum Chess Board level as part of 'current Quantum Chess Board level' portion in the calculation of stake in the Daily Pot. Two boards can be combined with Utility NFTs to add their levels together.  
**Sunset Effects** : Counts as Quantum Chess Board Level × (Months Sunset) as part of 'sunset boards' portion in the calculation of stake in the Daily Pot.  

## Raffle Token NFT

**Sell Price** : N/A  
**Buy Price** : N/A  
**Other Ways To Earn**: Win a game in the play to earn queue.  
**Use(s)** : Can win a Utility NFT in the daily Raffle.  Each day that a ticket doesn't win its likelihood of winning the next raffle increases.     
**Sunset Effects** : N/A  

## Sell In The Marketplace

In addition to the in site uses anybody is welcome to sell any NFT on the open market, the Quantum Chess Project simply provides the floor. Hopefully, 
in the future we can integrate with the gamestop NFT marketplace and even something like a Raffle Ticket NFT might have value on the secondary market.  
`


export const daily_events : string = `# Daily Events 

## The Daily Pot

The Daily Pot is meant to pay out people who have taken an interest in the ownership of the company and get paid out based on the formula seen on the right.
The most important features of the formula are that you only get paid out if you have played a premium queue game that day, and you own a Quantum Chess Board 
NFT.  This incentivizes players to play premium games or buy Quantum Chess Board NFTs.  Both of these things are good for the bottom line.  The savvy gamer 
will also realize that it is useful to have a few sunset tokens to their name which is another revenue stream (see **Bookkeeping**)

## The Daily Raffle

The Daily Raffle is meant to get value into the hands of the play to earn players.  At the end of each day Raffle Ticket NFTs will be exchanged for 
Utility NFTs (worth at least $0.25), there will not be more Utility tokens given out than are backed by revenue from other aspects of the project.  Hence, some 
Raffle Ticket NFTs will not win a Utility NFT each of the non-winning Raffle Ticket NFTs will have an increased chance at winning the next raffle.

To have one of your raffle tickets win you must play atleast one game in the play to earn queue that day.

## Incentives 

Both the daily payouts only pay players who have played on a prticular day, this will help give Quantum Chess staying power as players have a reason to queue up each and every day.
Specifically, players are incentivized to play in the premium queue to get their share of the daily pot and to earn that months Quantum Chess Board as earning a quantum chess board 
in each month will exponentially compound the stake a player has in the Daily Pot.  

`



export const road_map :string = `# Road Map

What follows is a road map of the project so far and the schedule that will be followed if the project is funded.

## Timeline

1. Start of October 2022.  Start the project. ✓
2. End of October 2022. Finish local single player version. ✓
3. End of November 2022. Finish and polish local multi player version. ✓
4. End of December 2022. Finish hosting the game on AWS capable of hosting 1000 2 player games simultaneously per server instance. ✓  
5. Mid January 2023.  Finish landing page, write rules and white paper, add time controls and multiple play queues. ✓  
6. End of January 2023.  Find investor money to keep the project alive (We are here)
7. End of February 2023. Create user accounts and book-keeping for the various play queues.
8. End of March 2023.  Connect to user accounts to a Web 3.0 Ethereum layer 2. Begin testnet testing.
9. End of April 2023.  Fine tune the website and get player feedback, add as many anti-cheat measures as possible.
10. One month allowed for set backs of an unforseen nature. AKA the unknown unknowns. This puts us at the End of May.
11. End of June 2023. Scale the AWS services to be set up to handle world wide internet traffic. Add as many quality of life features as possible. 
12. End of July 2023. Lay the ground work for marketing make adds, select specific streamers who we think would be best.  
13. Mid August 2023. Begin advertisements.
14. End of August 2023 Launch.

The above timeline gives ample time to get everything exactly right while each month hits a major milestone.  Obviously this is subject to change 
as the project takes shape but so far it has proved to be a good guide to focus my attention during the development of Quantum Chess.
`

export const marketing :string = `# Marketing

The main marketing avenues we want to target are Reddit/Twitch/YouTube/Twitter.  The marketing campaign 
is still a ways into the future but it is never too early to start planning.

## Reddit 

The following is a non-exhaustive list of subreddits that would be a good place to advertise

1. /r/chess.  601K members who love playing chess
2. /r/poker.  229k  members who like ot make money in games of skill.
3. /r/anarchychess. 376K members who like wierd chess stuff.  
4. /r/superstonk. 856K members who have heard of NFT gaming, if quantum chess makes it onto the Gamestop NFT marketplace this reddit could be a big help.
5. /r/NFT 629 NFT collectors/speculators/enthusiasts
6. /r/play2earn 12K members who want to be able to play to earn

Reddit offers targeted ads towards groups who would be predisposed to playing quantum chess.  This will be where most of the 'traditional' advertising 
for Quantum Chess will occur.

## Twitch 

On twitch we would try a more active or renegade advertising approach.  The plan would be to join chess streamers streams and challenge them to 
play quantum chess on stream.  The absolute best streamer to get would be GMHikaru (Hikaru Nakamura) with 1.6 million subs, but any major chess streamer 
would be great. At most times of the day there is a streamer with at least 5K viewers watching.  

Landing a big streamer on twitch would be the fastest waay to make the game go viral.

## YouTube

On YouTube the most important chess content creator is GothamChess with 2.3 million subscribers. If we could get him to play quantum chess for just a single 
episode it could be very worthwhile and is probably worth reaching out to him with a monetary offer to do such a thing.  His viewership skews younger and 
he knows a thing or two about making a video go viral.  Getting into one of his videos would be a gigantic landmark.

Another option on YouTube would be to get several influencers (the bigger the better) to participate in a quantum chess tournament w/ announcers etc. There
was a similar event called POG champs where influencers played in a normal chess tournament.  In general events like this are great because they hit a wider younger 
audience.

## Twitter 

Twitter is a hotbed for NFT and play to earn projects.  If we could get Quantum Chess on some of the larger twitter accounts we could be directly 
promoted to the peopl most active in bringing attention to play to earn projects.  This is the platform I am least familiar with so there will definitely 
be a learning curve involved.


`

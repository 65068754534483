import { Component } from 'react';
import Button from '../../Components/Button/Button';
import {play, navbar, navbar_emphasis} from '../../Constants/buttonStyles'
import './NavBar.css'

type State = {
    left_button_array : JSX.Element[]
    right_button_array : JSX.Element[]
    left_column_style : string
    right_column_style : string
}

type Props = {
    left_buttons : string[]
    right_buttons : string[]
    grid_size : number
    current_page : string
    callback : (route : string) => void
}


export default class NavBar extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {
            left_button_array : this.get_button_array(this.props.left_buttons, 'left', this.props.current_page),
            left_column_style : this.get_column_style(this.props.left_buttons.length, 'left', this.get_sum()),
            right_button_array : this.get_button_array(this.props.right_buttons, 'right', this.props.current_page),
            right_column_style : '0.5fr 1fr 0.5fr',
        }
        this.get_button_array.bind(this)
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.current_page !== prevProps.current_page){
            this.setState({
                left_button_array : this.get_button_array(this.props.left_buttons, 'left', this.props.current_page),
                left_column_style : this.get_column_style(this.props.left_buttons.length, 'left', this.get_sum()),
                right_button_array : this.get_button_array(this.props.right_buttons, 'right', this.props.current_page),
                right_column_style : '0.5fr 1fr 0.5fr'
            })
        }
    }

    get_sum(){
        let sum = 0 
        for (let i = 0; i < this.props.left_buttons.length; i ++){
            let name = this.props.left_buttons[i]
            sum += name.length + 1
        }
        return sum
    }

    get_button_array(button_names : string[], side : string, current_page : string){
        let buttons = side === 'left' ? this.props.left_buttons : this.props.right_buttons
        let button_arr = []
        for (let button_name in button_names){
            let name = buttons[button_name]
            if (name === 'Play' && current_page !== 'Play'){
                button_arr.push(<Button name = {name} handle_click = {this.props.callback} key={button_name} style = {play} class_name = 'play-button-in-navbar'/>)
            } else if (name !== 'Play'){
                if (name === current_page){
                    button_arr.push(<Button name = {name} handle_click = {this.props.callback} key={button_name} style = {navbar_emphasis} class_name = 'wrapper'/>)
                } else {
                    button_arr.push(<Button name = {name} handle_click = {this.props.callback} key={button_name} style = {navbar} class_name = 'wrapper'/>)
                }
            }
        }
        return button_arr
    }

    get_column_style(num_buttons : number , side:string, sum : number){
        let dummy_string = ''
        for (let i = 0; i < num_buttons; i ++){
            let name = this.props.left_buttons[i]
            dummy_string += `${7.5 / 4 * this.props.grid_size}px `
        }
        return dummy_string + ' auto'
    }

    render(){
        return<div className = 'button-bar' style = {{gridTemplateColumns: `${1 * this.props.grid_size}px 0.01fr 0.01fr 9.9fr 0.35fr 3.5fr`,
                                                        fontSize : `${this.props.grid_size/3.25}px`}}>
                <div className='nav-bar-separator'/>
                <div className='left-button-area'>        
                        <div className='left-nav-bar-buttons' style = {{gridTemplateColumns : this.state.left_column_style}}>
                                    {this.state.left_button_array}
                        </div>
                </div>
                    <div className='right-button-area'>        
                        <div className='right-nav-bar-buttons' style = {{gridTemplateColumns : this.state.right_column_style}}>
                                    {this.state.right_button_array}
                        </div>
                    </div>
                <div className='wrinkle-brain-logo' style={{ backgroundImage: `url(assets/wrinkle_brain.png` }}/>
            </div>

    }
}
import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import './Chapters.css'
import Button from '../../Components/Button/Button'
import { sidenav, sidenav_emphasis } from '../../Constants/buttonStyles'
import ForwardBackArrows from '../../Components/ForwardBackwardArrows/ForwardBackwardArrows';
import PicturePopUp from '../../Components/PicturePopUp/PicturePopUp';
import Preload from '../../Components/Preload/Preload';


type State = {
    markdown: string
    table_of_contents: JSX.Element[]
    current_page: string
    row_style: string
    scroll_ref: React.RefObject<HTMLDivElement>
    index: number
    hide_arrows: boolean
    popup_visible : boolean
    popup_url? : string
}

type Props = {
    grid_size: number
    width: number
    height: number
    names: string[]
    pictures: string[][]
    chapters: Record<string, string>
}


export default class Chapters extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            current_page: this.props.names[0],
            index: 0,
            markdown: this.props.chapters[this.props.names[0]],
            table_of_contents: this.get_toc(0),
            row_style: this.get_row_style(),
            scroll_ref: React.createRef(),
            hide_arrows: true,
            popup_visible : false
        }
        this.toc_button_clicked.bind(this)
    }

    componentDidMount(): void {
        this.setState({
            hide_arrows: this.should_show_buttons(),
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.names !== prevProps.names) {
            console.log('here')
            this.setState({
                current_page: this.props.names[0],
                index: 0,
                markdown: this.props.chapters[this.props.names[0]],
                table_of_contents: this.get_toc(0),
                row_style: this.get_row_style(),
                scroll_ref: React.createRef(),
                popup_visible : false,
                popup_url : undefined
            }, () => {
                this.setState({
                    hide_arrows: this.should_show_buttons()
                })
            })
        }
    }

    increment_page = (increment: number) => {
        let page_number = this.state.index + increment
        page_number = Math.max(0, page_number)
        page_number = Math.min(page_number, this.props.names.length - 1)
        console.log(page_number)
        var scroll_box = document.getElementsByClassName("rules-words")[0]
        scroll_box.scrollTop = 0
        console.log(scroll_box.scrollHeight, scroll_box.clientHeight, scroll_box.scrollTop)
        this.setState({
            current_page: this.props.names[page_number],
            markdown: this.props.chapters[this.props.names[page_number]],
            index: page_number,
            table_of_contents: this.get_toc(page_number),
            popup_visible : false,
            popup_url : undefined
        }, () => {
            this.setState({
                hide_arrows: this.should_show_buttons()
            })
        })
    }

    toc_button_clicked = (name: string) => {
        var scroll_box = document.getElementsByClassName("rules-words")[0]
        scroll_box.scrollTop = 0
        this.setState({
            current_page: name,
            markdown: this.props.chapters[name],
            index: this.props.names.indexOf(name),
            table_of_contents: this.get_toc(this.props.names.indexOf(name)),
            popup_visible : false,
            popup_url : undefined
        }, () => {
            this.setState({
                hide_arrows: this.should_show_buttons()
            })
        })
    }

    should_show_buttons() {
        var scroll_box = document.getElementsByClassName("rules-words")[0]
        return scroll_box.scrollHeight - scroll_box.clientHeight > scroll_box.scrollTop + 5
    }

    handle_scroll(e: React.UIEvent) {
        let scroll_box = e.target as HTMLElement
        console.log(scroll_box.scrollHeight, scroll_box.clientHeight, scroll_box.scrollTop)
        if (scroll_box.scrollHeight - scroll_box.clientHeight < scroll_box.scrollTop + 10) {
            this.setState({
                hide_arrows: false
            })
        }

    }

    popup(index : number){
        this.setState({
            popup_visible: true,
            popup_url : this.props.pictures[this.state.index][index]
        })
    }

    popup_close_callback = () => {
        this.setState({
            popup_visible: false,
            popup_url : undefined
        })
    }

    get_font_size(){
        var toc = document.getElementsByClassName('toc-container')
        let size : number  = 0
        if (toc.length > 0)
            size = Number(toc[0].scrollWidth)/9  
        return size.toString() + 'px'
    }

    get_row_style() {
        let row_style = ''
        let font_size = Math.min(this.props.width / 63, this.props.height / 40)
        for (let i = 0; i < this.props.names.length; i++) {
            row_style += (1.5 * font_size).toString() + 'px '
        }
        row_style += 'auto'
        return row_style
    }

    get_toc(index: number) {
        let toc: JSX.Element[] = []
        for (let i = 0; i < this.props.names.length; i++) {
            if (i === index) {
                toc.push(<Button name={this.props.names[i]} class_name='toc-button' handle_click={this.toc_button_clicked} style={sidenav_emphasis} key={i} />)
            } else {
                toc.push(<Button name={this.props.names[i]} class_name='toc-button' handle_click={this.toc_button_clicked} style={sidenav} key={i} />)
            }
        }
        return toc
    }

    render() {
        return <div className='rules-page' style={{gridTemplateColumns: `${9 / 4 * this.props.grid_size}px 0.01fr 0.35fr 10fr 0.35fr 3.5fr`}}>
            <div className='picture-sidebar'>
                <Preload key = {0} layout = 'picture-1' 
                        loaded_jsx={[<div className='pic' style={{ backgroundImage: this.state.index < this.props.pictures.length ? `url(assets/${this.props.pictures[this.state.index][0]})` : 'none' }}  onClick = {(e) => {this.popup(0)}}/>]}
                        images = {[this.props.pictures[this.state.index][0]]}
                        />
                <Preload key = {1} layout = 'picture-2' 
                        loaded_jsx={[<div className='pic' style={{ backgroundImage: this.state.index < this.props.pictures.length ? `url(assets/${this.props.pictures[this.state.index][1]})` : 'none' }}  onClick = {(e) => {this.popup(1)}}/>]}
                        images = {[this.props.pictures[this.state.index][1]]}
                        />      
            </div>
            <div className='vertical-sep' />
            <div className='toc-container'>
                <div className='table-of-contents' style={{
                    gridTemplateRows: this.state.row_style,
                    fontSize: this.get_font_size()
                }}>
                    {this.state.table_of_contents}
                </div>
            </div>
            <div className='rules-words'
                style={{ height: this.props.grid_size * 9, 
                    fontSize: this.props.grid_size / 5}}
                ref={this.state.scroll_ref}
                onScroll={e => this.handle_scroll(e)}>
                <ReactMarkdown className='markdown'>{this.state.markdown}</ReactMarkdown>
            </div>
            <Preload layout = 'arrow-bar' loaded_jsx={[
                <ForwardBackArrows callback={this.increment_page}
                    hidden={this.state.hide_arrows}
                    length={this.props.names.length}
                    current_num={this.state.index}
                    divisor={1}
                />]} 
                images = {['arrow.png']}
            />
            <PicturePopUp visible = {this.state.popup_visible} 
                        picture_url = {this.state.popup_url} 
                        grid_size = {this.props.grid_size}
                        callback = {this.popup_close_callback}/>
        </div>

    }
}
export const play = {
    style: {
        margin: 'auto',
        backgroundColor: 'green',
        borderColor: '#f0f9ff',
        borderStyle: 'inset',
        height: '75%',
        width: '100%',
        justifyItems: 'center',
        alignItems: 'center'
    },
    hover_width: '80%',
    hover_height: '65%',
    width: '100%',
    height: '75%',
    hover_color: 'ghostwhite',
    color: 'ghostwhite',
    background_color: 'green',
    hover_background_color: 'forestgreen'
}

export const navbar = {
    style: {
        display: 'grid',
        margin: 'auto',
        justifyItems: 'center',
        alignItems: 'center',
        width: '90%',
        height: '85%',
    },
    hover_width: '90%',
    hover_height: '85%',
    width: '90%',
    height: '85%',
    hover_color: 'antiquewhite',
    color: 'ghostwhite',
    background_color: 'inherit',
    hover_background_color: 'inherit'
}


export const navbar_emphasis = {
    style: {
        display: 'grid',
        margin: 'auto',
        justifyItems: 'center',
        alignItems: 'center',
        textDecorationLine: 'underline',
        width: '90%',
        height: '85%',
    },
    hover_width: '90%',
    hover_height: '85%',
    width: '90%',
    height: '85%',
    hover_color: 'antiquewhite',
    color: 'ghostwhite',
    background_color: 'inherit',
    hover_background_color: 'inherit'
}




export const sidenav = {
    style: {
        display: 'grid',
        width: '75%',
        height: '85%',
        color: 'ghostwhite',
        justifyItems: 'left',
        justifySelf: 'left',
    },
    hover_width: '75%',
    hover_height: '85%',
    width: '75%',
    height: '85%',
    hover_color: 'antiquewhite',
    color: 'ghostwhite',
    background_color: 'inherit',
    hover_background_color: 'inherit'
}

export const sidenav_emphasis = {
    style: {
        display: 'grid',
        width: '75%',
        height: '85%',
        color: 'ghostwhite',
        justifyItems: 'left',
        justifySelf: 'left',
        textDecorationLine: 'underline'
    },
    hover_width: '75%',
    hover_height: '85%',
    width: '75%',
    height: '85%',
    hover_color: 'antiquewhite',
    color: 'ghostwhite',
    background_color: 'inherit',
    hover_background_color: 'inherit'
}
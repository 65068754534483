import { useEffect , useRef} from "react"
import { Socket } from "socket.io-client"

type MatchMakerProps = {
    socket: Socket;
    callback : any;
  }; 

export const MatchMaker = ({ socket , callback} : MatchMakerProps) => {
    useEffect(() => {
      socket.on("ready", (data) => {
        console.log('matchmaker')
        callback(data)
      });
    }, [])
    return null // component does not render anything
  }
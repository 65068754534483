import { Component } from 'react';
import './PicturePopUp.css'

type State ={

}

type Props = {
    grid_size : number
    picture_url? : string,
    visible : boolean
    callback : () => void
}


export default class PicturePopUp extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {
        }
    }
    
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.visible !== prevProps.visible || this.props.grid_size !==prevProps.grid_size){
               this.forceUpdate()
       }
    }


    render() {
        if (this.props.visible){
            return <div  className='picture-pop-up-wrapper' style={{ gridTemplateColumns : `auto ${this.props.grid_size*8.}px auto`,
                                                                    gridTemplateRows : `auto ${this.props.grid_size*8.}px auto`}}>
                        <div className='picture-pop-up-frame'>
                            <div className='picture-pop-up' style={{ backgroundImage: `url(assets/${this.props.picture_url})` }}/>
                            <div className='popup-close-button' style={{ backgroundImage: `url(assets/red_X.png)` ,backgroundRepeat : 'no-repeat'}} 
                                                                onClick={this.props.callback}></div>
                        </div>
                    </div>
        } else {
            return null
        }
    }
}
export const queue_selector_style = (grid_size : number) => {
    let custom_style = {
    control: (base : any, state : any) => ({
      ...base,
      backgroundColor: "black",
      color :  'white',
      height: grid_size,
      minHeight: grid_size,
      fontSize : grid_size/2.5,
      
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "antiquewhite" : "antiquewhite",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "antiquewhite" : "antiquewhite"
      }
    }),

    dropdownIndicator: (base : any, state : any) => ({
        ...base,
        // height: grid_size,
        // minHeight: grid_size,
        display:'grid',
        width: grid_size,
        minWidth: grid_size,
        height: grid_size,
        minHeight: grid_size,
        fontSize : grid_size/2.5,
        padding: '0px 0px',
        justifyItems : 'center',
        alignItems : 'center'
    }),

    indicatorsContainer: (base : any, state : any) => ({
        ...base,
        height: grid_size,
        minHeight: grid_size,
    }),

    valueContainer: (base : any, state : any) => ({
        ...base,
        padding : '0% 5%',
        outerHeight : '100%',
        borderRadius : '0px'
    }),

    singleValue: (base : any) => ({
        ...base,
        color: 'white',
        margin: 'auto',
        fontSize : grid_size/2.5,
      }),
    menu: (base : any, state : any) => ({
      ...base,
      background: "black",
      color : 'white',
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      fontSize : grid_size/2.5,
    }),
    menuList: (base : any, state : any) => ({
      ...base,
      background: "antiquewhite", 
      color : 'black',
      // kill the white space on first and last option
      padding: 0,
      fontSize : grid_size/2.5,
    }),
    option: (styles : any, { data, isDisabled, isFocused, isSelected } : any) => {
        return {
          ...styles,
          fontSize : grid_size/2.5,
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? 'antiquewhite'
            : isFocused
            ? 'ghostwhite'
            : undefined,
          color: isDisabled
            ? undefined
            : isSelected
            ? 'gray'
            : isFocused
            ? 'black'
            : undefined,
  };
    }
}
return custom_style
}
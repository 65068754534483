// Make a static game board with queue options in the sideboard 
import { Component } from 'react';
import { Socket } from "socket.io-client"
import { GameType, TeamType } from '../../Constants/constants'
import TwoPlayerChessBoard from '../../Components/TwoPlayerChessBoard/TwoPlayerChessBoard';
import PlayLobby from './PlayLobby/PlayLobby'
import Preload from '../../Components/Preload/Preload';
import './Play.css'
import Button from '../../Components/Button/Button'
import {play} from '../../Constants/buttonStyles'


type State = {
    screen : JSX.Element | null,
    board_props : any
    message : string
    display_play_again : boolean
}

type Props = {
    grid_size : number,
    socket : Socket
}

export default class Play extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {
            screen : null, 
            board_props : null,
            message : '',
            display_play_again : false
        }
        this.get_screen.bind(this)
        this.play_lobby_callback.bind(this)
        this.message_callback.bind(this)
        this.play_again_callback.bind(this)
    }

    componentDidMount()
    {
        this.setState({
            screen : this.get_screen(this.state.board_props)
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.grid_size !== prevProps.grid_size){
                this.setState({
                    screen : this.get_screen(this.state.board_props)
                })
           }   
       }

    new_game = ()  => {
        this.setState({
            board_props : null,
            screen : this.get_screen(null),
            display_play_again : false
        })
    }

    play_again_callback = () => {
        this.setState({
            message : '',
            display_play_again : true
        })
    }

    message_callback = (new_message : string) => {
        this.setState(
            {
                message : new_message
            }
        )
    }

    play_lobby_callback = (game_type : any) => {
        this.setState({
            board_props : game_type,
            screen : this.get_screen(game_type)
        })
    }

    get_screen(board_props : any | null){
        let screen : JSX.Element

        if (board_props === null){
            screen = <PlayLobby grid_size = {this.props.grid_size} socket = {this.props.socket} callback = {this.play_lobby_callback}/>
        }         
        else {
            let user_team = board_props.user_team === 'white' ? TeamType.WHITE : TeamType.BLACK
            console.log(user_team)
            screen = <TwoPlayerChessBoard socket = {this.props.socket} 
                                        user_team = {user_team} 
                                        room_name = {board_props.room_name}
                                        grid_size = {this.props.grid_size} 
                                        init_time = {board_props.init_time} 
                                        delay = {board_props.delay}
                                        mode = {board_props.mode}
                                        increment = {board_props.increment}
                                        en_passant = {board_props.en_passant}
                                        castling = {board_props.en_passant}
                                        random_capture = {board_props.random_capture}
                                        decoherence = {board_props.decoherence}
                                        message_callback = {this.message_callback}
                                        play_again_callback = {this.play_again_callback}
                                        ></TwoPlayerChessBoard>
        } 
        return screen
    }

    render() {
        return <div className='play-wrapper' style = {{gridTemplateColumns : `auto ${8*this.props.grid_size}px 
                                                                            ${0.25*this.props.grid_size}px 
                                                                            ${3*this.props.grid_size}px 
                                                                            auto`, 
                                                        gridTemplateRows : `auto ${1*this.props.grid_size}px 
                                                                            ${8*this.props.grid_size}px 
                                                                            ${0.125*this.props.grid_size}px 
                                                                            ${1*this.props.grid_size}px auto`}}>
                <div className='message-display'>
                    <div className='message-text' key={this.state.message}
                        style = {{display : this.state.message === '' ? 'none' : 'grid',
                                fontSize : `${this.props.grid_size/6}px`}}>
                        {this.state.message}
                    </div>
                </div>
                <div className='play-again-wrapper' style = {{display : this.state.display_play_again ? 'grid' : 'none'}}>
                    <Button name = {'Play Again'} handle_click = {this.new_game} key={1} style = {play} class_name = 'wrapper'/>
                </div>

                {this.state.screen}  
            </div>

    }
}
import { string_index_converter, Piece, PieceType, TeamType, piece_converter } from "../Constants/constants";
import { getPossiblePieces } from "./referee";
import { promotion_array } from "./sorter";

const all_pieces = [PieceType.KING, PieceType.QUEEN, PieceType.ROOK, 
                    PieceType.BISHOP, PieceType.NIGHT, PieceType.PAWN]
const init_piece_totals = [1, 1, 2, 2, 2, 8]
const promo = [0, 0, 0, 0, 0, -1]
const promo_possible = [PieceType.QUEEN, PieceType.ROOK, PieceType.BISHOP, PieceType.NIGHT]

export function fullReduction(board_state: Piece[], black_captured: (string | null)[], white_captured: (string | null)[],
    team: TeamType, white_promoted: number, black_promoted: number, should_remove_kings: boolean,
    user_team : TeamType, verbose : boolean = false) {
    let something_changed = true
    let working_state = [...board_state]
    let w_captured = convert_to_num(white_captured)
    let b_captured = convert_to_num(black_captured)
    remove_kings(working_state, team, should_remove_kings, user_team)
    only_one_king(working_state, TeamType.WHITE)
    only_one_king(working_state, TeamType.BLACK)
    while (something_changed === true) {
        const white = combined_collapse(working_state, w_captured, TeamType.WHITE, white_promoted, verbose)
        const black = combined_collapse(working_state, b_captured, TeamType.BLACK, black_promoted, verbose)
        const king_redux_white = only_one_king(working_state, TeamType.WHITE)
        const king_redux_black = only_one_king(working_state, TeamType.BLACK)
        something_changed = white || black || king_redux_white || king_redux_black
    }
    return working_state
}

function combined_collapse(board_state: Piece[], captured: number[], team: TeamType, num_promoted: number, verbose : boolean): boolean {
    let something_changed: boolean = false
    let stop = false
    var combos: { [piece: string]: number[]} = {};
    let modified_totals: number[] = []
    for (let i = 0; i < captured.length; i++) {
        modified_totals.push(init_piece_totals[i] - captured[i] + num_promoted*promo[i])
    }
    combined_recursion(modified_totals, combos, 0, '', board_state, team)
    if (verbose){
        console.log(combos, team)
    }
    Object.entries(combos).forEach(([piece_combo, total]) => {
        if (!stop){
            if (total[1] >= total[0] + num_promoted) {
                if (!(total[1] >= total[0])){
                    num_promoted -= 1
                    something_changed = true
                }
                //Do Collapse
                for (let j = 0; j < board_state.length; j++) {
                    const SP = board_state[j].SuperPiece
                    if (SP.team === team) {
                        const dummy_string = piece_to_string(SP.subPieces)
                        if (!equals(dummy_string, piece_combo)) {
                            remove_others(SP.subPieces, piece_combo)
                            const check_string = piece_to_string(SP.subPieces)
                            if (!equals(dummy_string, check_string)) {
                                stop = true
                                something_changed = true
                            }
                        }
                    }
                }
            }
        }
    })
    return something_changed
}

function combined_recursion(totals: number[], combo_totals: { [piece: string]: number[]; }, i: number,
    s: string, board_state: Piece[], team: TeamType) {
    let piece_string = piece_to_string(all_pieces)
    for (let j = i; j < piece_string.length; j++) {
        let new_char = piece_string.charAt(j)
        s += new_char
        if (s.length > 1) {
            combo_totals[s] = [totals[j] + combo_totals[s.slice(0, -1)][0], count(board_state, team, s)]
        }
        else {
            combo_totals[s] = [totals[j], count(board_state, team, s)]
        }
        combined_recursion(totals, combo_totals, j + 1, s, board_state, team)
        s = s.slice(0, -1)
    }
}





function remove_kings(board_state: Piece[], team: TeamType, should_remove_kings: boolean, user_team : TeamType) {
    if (should_remove_kings) {
        const enemy_team = team === TeamType.WHITE ? TeamType.BLACK : TeamType.WHITE
        for (let i = 0; i < board_state.length; i++) {
            if (board_state[i].SuperPiece.team === enemy_team && board_state[i].SuperPiece.subPieces.length < 3) {
                for (let j = 0; j < board_state.length; j++) {
                    if (board_state[j].SuperPiece.team === team) {
                        const result = getPossiblePieces(board_state[i].x, board_state[i].y, board_state[j].x, board_state[j].y,
                            board_state[i].SuperPiece, board_state, enemy_team, null, user_team);
                        if (result.possible_pieces.length > 0 && board_state[j].SuperPiece.subPieces.includes(PieceType.KING)) {
                            let index = board_state[j].SuperPiece.subPieces.indexOf(PieceType.KING)
                            board_state[j].SuperPiece.subPieces.splice(index, 1)
                        }
                    }
                }
            }
        }
    }
}

function only_one_king(board_state: Piece[], team : TeamType){
    let king_count = 0 
    let index = -1
    for (let i = 0; i < board_state.length; i++) {
        if (board_state[i].SuperPiece.team === team && board_state[i].SuperPiece.subPieces.includes(PieceType.KING)){
            king_count += 1 
            if (king_count === 1){
                index = i
            }    
        }
    }
    if (king_count === 1){
        if (board_state[index].SuperPiece.subPieces.length > 1){
            board_state[index].SuperPiece.subPieces = [PieceType.KING]
            return true
        }
    }
    return false
}





// Helper Functions Helper Functions  Helper Functions  Helper Functions Helper Functions Helper Functions Helper Functions Helper Functions 
// These should be welf explanatory
function convert_to_num(captured: (string | null)[]) {
    let ans: number[] = [0, 0, 0, 0, 0, 0]
    for (let i = 0; i < captured.length; i++) {
        const s = captured[i]
        if (s) {
            const index = string_index_converter(s)
            ans[index] += 1
        }
    }
    return ans
}

function contains_all_chars(str: string, substring: string) {
    var arr_str = str.toLowerCase().split('');
    var arr_substr = substring.toLowerCase().split('');
    return arr_substr.filter(function (each) {
        return arr_str.indexOf(each) === -1;
    }).length === 0;
}

function piece_to_string(subpieces: PieceType[]) {
    let ans: string = ''
    for (let i = 0; i < subpieces.length; i++) {
        ans += subpieces[i]
    }
    return ans
}
function string_to_piece(subpieces: string) {
    let ans: PieceType[] = []
    for (let i = 0; i < subpieces.length; i++) {
        ans.push(piece_converter(subpieces.charAt(i)))
    }
    return ans
}

function count(board_state: Piece[], team: TeamType, combo: string) {
    let ans: number = 0
    for (let i = 0; i < board_state.length; i++) {
        let SP = board_state[i].SuperPiece
        if (SP.team === team) {
            let dummy_string: string = piece_to_string(SP.subPieces)
            if (equals(dummy_string, combo))
                ans += 1
        }
    }
    return ans
}

function equals(arr1: string, arr2: string) {
    return contains_all_chars(arr1, arr2) && contains_all_chars(arr2, arr1)
}

function remove_others(subPieces: PieceType[], combo: string) {
    for (let i = 0; i < combo.length; i++) {
        let char = combo.charAt(i)
        let p = string_to_piece(char)[0]
        let index = subPieces.indexOf(p)
        if (index >= 0) {
            subPieces.splice(index, 1);
        }
    }
}





import { resourceLimits } from "worker_threads";
import { Piece, PieceType, TeamType } from "../Constants/constants";
import SuperPiece from "../Constants/superPiece";
import { getPossiblePieces, possible_pieces_with_king } from "./referee";



export function game_over(board_state: Piece[]) {
    let is_game_over: string | null = null
    const white_has_king = has_king(board_state, TeamType.WHITE)
    const black_has_king = has_king(board_state, TeamType.BLACK)
    if (white_has_king && !black_has_king) {
        is_game_over = 'WHITE Wins. Black has no king.'
    }
    if (!white_has_king && black_has_king) {
        is_game_over = 'BLACK wins. White is in checkmate'
    }
    if (!white_has_king && !black_has_king) {
        is_game_over = 'DRAW. Niether player has a king.'
    }
    return is_game_over
}

export function has_king(board_state: Piece[], team: TeamType) {
    let ans: { has_king: boolean, index: number } = { has_king: false, index: -1 }
    for (let i = 0; i < board_state.length; i++) {
        let SP = board_state[i].SuperPiece
        if (SP.team === team) {
            if (SP.team === team && SP.subPieces.includes(PieceType.KING)) {
                ans.has_king = true
            }
            if (SP.team === team && SP.subPieces.length === 1 && SP.subPieces[0] === PieceType.KING) {
                ans.index = i
            }
        }
    }
    return ans
}

function deepCopy(board_state: Piece[]) {
    const oldBoardState: Piece[] = [];
    board_state.forEach(p => oldBoardState.push({
        SuperPiece: new SuperPiece([...p.SuperPiece.subPieces], p.SuperPiece.team),
        x: p.x, y: p.y, promoted: p.promoted
    }))
    return oldBoardState
}

export function king_can_be_captured(board_state: Piece[], king_index: number, user_team: TeamType) {
    const king = board_state[king_index]
    const enemy_team = king.SuperPiece.team === TeamType.WHITE ? TeamType.BLACK : TeamType.WHITE
    for (let i = 0; i < board_state.length; i++) {
        let P = board_state[i]
        let result = getPossiblePieces(P.x, P.y, king.x, king.y, P.SuperPiece, board_state, enemy_team, null, user_team)
        let pp = result.possible_pieces
        if (pp.length > 0) {
            return true
        }
    }
    return false
}


// could try to add a was blockaded trace back to add speed by stopping some loops early if a piece cant move due to blocakde
// could add check on piece type too 
export function is_checkmate(board_state: Piece[], team: TeamType, captured_white_pieces: (string | null)[],
                                        captured_black_pieces: (string | null)[], 
                                        white_promoted: number, black_promoted: number,
                                        user_team : TeamType) {
    const horse_moves = [[1, 2], [2, 1], [-1, 2], [2, -1], [1, -2], [-2, 1], [-1, -2], [-2, -1]]
    for (let i = 0; i < board_state.length; i++) {
        if (board_state[i].SuperPiece.team === team) {
            for (let j = -7; j < 8; j++) {
                if (j !== 0){
                    // horizontal moves
                    let px = board_state[i].x
                    let py = board_state[i].y
                    let piece = board_state[i].SuperPiece
                    let square_x = px + j
                    let square_y = py + j
                    let neg = px - j
                    if (piece.subPieces.includes(PieceType.ROOK) || piece.subPieces.includes(PieceType.QUEEN) || 
                                                    (Math.abs(j) === 1 && piece.subPieces.includes(PieceType.KING)) ||
                                                    (Math.abs(j) <= 2 && piece.subPieces.includes(PieceType.PAWN))){
                        if (square_x < 8 && square_x >= 0) {
                            const horizontal_state = deepCopy(board_state)
                            const result = possible_pieces_with_king(px, py, square_x, py, piece, horizontal_state, team,
                                null, captured_white_pieces, captured_black_pieces,
                                white_promoted, black_promoted, user_team)
                            if (result.possible_pieces.length > 0) {
                                return false
                            }
                        }
                        //vertical moves 
                        if (square_y < 8 && square_y >= 0) { 
                            const vertical_state = deepCopy(board_state)
                            const result = possible_pieces_with_king(px, py, px, square_y, piece, vertical_state, team,
                                null, captured_white_pieces, captured_black_pieces,
                                white_promoted, black_promoted, user_team)
                            if (result.possible_pieces.length > 0) {
                                return false
                            }
                        }
                    }
                    //diagonal moves 
                    if (piece.subPieces.includes(PieceType.BISHOP) || piece.subPieces.includes(PieceType.QUEEN) || 
                                                (Math.abs(j) === 1 && piece.subPieces.includes(PieceType.KING)) ||
                                                (Math.abs(j) === 1 && piece.subPieces.includes(PieceType.PAWN))){
                        if (square_y < 8 && square_y >= 0 && square_x < 8 && square_x >= 0) {
                            const positive_diagonal_state = deepCopy(board_state)
                            const result = possible_pieces_with_king(px, py, square_x, square_y, piece, positive_diagonal_state, team,
                                null, captured_white_pieces, captured_black_pieces,
                                white_promoted, black_promoted, user_team)
                            if (result.possible_pieces.length > 0) {
                                return false
                            }
                        }
                        if (square_y < 8 && square_y >= 0 && neg < 8 && neg >= 0) {
                            const negative_diagonal_state = deepCopy(board_state)
                            const result = possible_pieces_with_king(px, py, neg, square_y, piece, negative_diagonal_state, team,
                                null, captured_white_pieces, captured_black_pieces,
                                white_promoted, black_promoted, user_team)
                            if (result.possible_pieces.length > 0) {
                                return false
                            }
                        }
                    }
                    //horse moves 
                    if (piece.subPieces.includes(PieceType.NIGHT)){
                        if (j >= 0 && j < 8 && piece.subPieces.includes(PieceType.NIGHT)) {
                            let horse_x = px + horse_moves[j][0]
                            let horse_y = py + horse_moves[j][1]
                            const horse_state = deepCopy(board_state)
                            if (horse_y < 8 && horse_y >= 0 && horse_x < 8 && horse_y >= 0) {
                                const result = possible_pieces_with_king(px, py, horse_x, horse_y, piece, horse_state, team,
                                    null, captured_white_pieces, captured_black_pieces,
                                    white_promoted, black_promoted, user_team)
                                if (result.possible_pieces.length > 0) {
                                    return false
                                }
                            }
                        }
                    } 
                }
            }
        }
    }
    return true
}



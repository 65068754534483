/*
This function updates the Piece [] so that the piece moves to its new location. It
also handles the case where a piece is taken and when a ROOK is teleported via 
the castling move.
*/

import { Piece, PieceType, TeamType } from '../Constants/constants'
import SuperPiece from '../Constants/superPiece'

interface GameState{
    board_state : Piece[],
    taken_piece : Piece | null,
    promoted : boolean
}

export function executeMove (px : number, py : number, x : number, y: number,
                            possiblePieces : PieceType [], activePlayer : TeamType, 
                            boardState : Piece [], user_team : TeamType = TeamType.WHITE) {
    let working_copy = deepCopy(boardState)
    const takenPiece = working_copy.find(p => p.x === x && p.y === y && p.SuperPiece.team !== activePlayer)
    let promotion : boolean = false
    if (takenPiece){
        takePiece(takenPiece, working_copy)
    } 
    const currentPiece = working_copy.find(p => p.x === px && p.y === py) 
    if (currentPiece){
        const id = working_copy.indexOf(currentPiece)
        if (((y === 7) || (y === 0)) && possiblePieces.includes(PieceType.PAWN)){
            working_copy[id] = {SuperPiece : new SuperPiece([PieceType.QUEEN, PieceType.ROOK, PieceType.BISHOP, PieceType.NIGHT], activePlayer), x, y, promoted : true}
            promotion = true
        }  else {
            working_copy[id] = {SuperPiece : new SuperPiece(possiblePieces, activePlayer), x, y, promoted : false}   
        } 
    }
    const taken = takenPiece ? takenPiece : null
    let ans : GameState = {board_state : working_copy, taken_piece : taken, promoted : promotion}
    return ans
}


function takePiece(p : Piece, boardState : Piece []){
    const id = boardState.indexOf(p)
    boardState.splice(id, 1)
}


function deepCopy(board_state : Piece []) {
    const oldBoardState = [...board_state]
    return oldBoardState
}
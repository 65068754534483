import { PieceType, TeamType } from "./constants"
import { custom_sort } from "../Referee/sorter";

export default class SuperPiece{
    subPieces : PieceType [] = [];
    team : TeamType = TeamType.WHITE;
    img_url : string = '';

    constructor (subPieces: PieceType [], team : TeamType){
        this.subPieces = subPieces 
        this.team = team
        this.getURL.bind(this)
    }

    setURL(skin : string, user_team : TeamType){
        let sp = user_team === TeamType.WHITE ? "sp" : "spf"
        let url = []
        let image_url = ''
        const dir = this.team === TeamType.WHITE ? 'white_pieces': 'black_pieces';
        for (let i = 0; i < this.subPieces.length; i ++){
            url.push(this.subPieces[i])
        }
        const sorted_url = custom_sort(url, ['k', 'q', 'r', 'b', 'n', 'p'])
        for (let j = 0; j < sorted_url.length; j ++){
                image_url += sorted_url[j]
        } 
        if (image_url.length === 1){
            this.img_url = `assets/${dir}/solo/check/${image_url}.png`
        }
        else {
            this.img_url = `assets/${dir}/${sp}/${image_url}.png`
        }
    }
    
    getURL(skin : string, user_team : TeamType){
        let sp = user_team === TeamType.WHITE ? "sp" : "spf"
        let url = []
        let image_url = ''
        let full_url = ''
        const dir = this.team === TeamType.WHITE ? 'white_pieces': 'black_pieces';
        for (let i = 0; i < this.subPieces.length; i ++){
            url.push(this.subPieces[i])
        }
        const sorted_url = custom_sort(url, ['k', 'q', 'r', 'b', 'n', 'p'])
        for (let j = 0; j < sorted_url.length; j ++){
                image_url += sorted_url[j]
        } 
        if (image_url.length === 1){
            full_url = `assets/${dir}/solo/check/${image_url}.png`
        }
        else {
            full_url = `assets/${dir}/${sp}/${image_url}.png`
        }
        return full_url
    }

    equals(arr : PieceType []) : boolean{
        if (this.subPieces.length !== arr.length){
            return false
        }
        for (let i = 0; i < this.subPieces.length; i ++){
            if (this.subPieces[i] !== arr[i]){
                return false
            }
        } 
        return true
    }
}
import React, { Component , useEffect} from 'react';
import Tile from '../Tile/tile';
import {AXIS, Piece, TeamType, PieceType, initializeBoardState, piece_converter, piece2string, Square, Move} from '../../Constants/constants'
import { possible_pieces_with_king} from '../../Referee/referee'
import { executeMove } from '../../Referee/executeMove';
import SuperPiece from '../../Constants/superPiece';
import  {fullReduction}  from '../../Referee/stateReducer';
import SideBoard from '../Sideboard/Sideboard';
import { promotion_array } from '../../Referee/sorter';
import './TwoPlayerChessBoard.css';
import {is_checkmate} from '../../Referee/game_over';
import {io, Socket} from 'socket.io-client';
import {MoveListener} from '../../Listeners/MoveListener';
import DisplayWinner from '../DisplayWinner/DisplayWinner';
import { Clock } from '../Clock/Clock'
import AudioFiles from '../Audio/Audio';



type State = {
    board : JSX.Element[],
    pieces : Piece[][],
    active_piece : HTMLElement | null
    chessboardRef : React.RefObject<HTMLDivElement>,
    grid_x : number,
    grid_y : number,
    active_player : TeamType, 
    move_number : number,
    captured : Piece | null, 
    captured_white_pieces : (string | null)[],
    captured_black_pieces : (string | null)[],
    captured_white_history : (string | null)[][],
    captured_black_history : (string | null)[][],
    move_history : Move [],
    white_promoted : number,
    black_promoted : number,
    game_over_message : string | null,
    is_game_over : boolean,
    page_open? : string,
    message : string,
    skin : string,
    clock : Clock,
    time : number[]
}


type Props = {
    socket : Socket
    user_team : TeamType,
    room_name : string,
    grid_size : number,
    init_time : number, 
    increment : number,
    delay : number,
    en_passant : boolean,
    castling : boolean,
    random_capture : boolean,
    decoherence : boolean,
    mode : string, 
    message_callback : (message : string) => void
    play_again_callback : () => void
}

export default class TwoPlayerChessBoard extends Component<Props, State> {
    // Before the component mounts, we initialise our state
    constructor(props : Props) { 
        super(props)
        this.state = {
            board: [],
            pieces: initializeBoardState(this.props.user_team),
            active_piece : null,
            chessboardRef : React.createRef(),
            grid_x : 0,
            grid_y : 0, 
            active_player : TeamType.WHITE, 
            move_number : 0, 
            captured : null,
            captured_white_pieces : [null],
            captured_black_pieces : [null],
            move_history : [{starting_square : undefined, ending_square : undefined}],
            captured_white_history : [[null]],
            captured_black_history : [[null]],
            white_promoted : 0,
            black_promoted : 0,  
            game_over_message : null,
            is_game_over : false,
            page_open : undefined,
            skin : 'default',
            message : 'White to ',
            clock : new Clock ({
                name : 'default', 
                stages : [{time : [this.props.init_time*60000,this.props.init_time*60000 - this.props.increment*1000], 
                            increment : this.props.increment*1000, 
                            delay : this.props.delay*1000, 
                            mode: 'Quantum'}], 
                updateInterval : 100,
                callback : () => this.set_time()
            }),
            time : [this.props.init_time*60000, this.props.init_time*60000, this.props.increment*1000]
         }
         this.draw_board.bind(this)
         this.addMove.bind(this)
         this.grabPiece.bind(this)
         this.movePiece.bind(this)
         this.dropPiece.bind(this)
         this.setActivePiece.bind(this)
         this.setGridX.bind(this)
         this.setGridY.bind(this)
         this.set_time.bind(this)
         this.setGameOver.bind(this)
         this.setPageOpen.bind(this)
         this.deepCopy.bind(this)
         this.switchPlayer.bind(this)
         this.revert.bind(this)
         this.sideBoardCallBack.bind(this)
         this.choosePieceCallBack.bind(this)
         this.setCaptured.bind(this)
         this.handlePromotion.bind(this)
         this.gameOverClose.bind(this)
         this.lower_bar_call_back.bind(this)
         this.settings_call_back.bind(this)
         this.move_call_back.bind(this)
         this.take_call_back.bind(this)
         this.dc_callback.bind(this)
         this.game_over_callback.bind(this)
         this.make_move.bind(this)
      }
    // Life-cycle methods BEGIN
    componentDidMount()
    {
        this.draw_board()
        this.state.clock.push(1)
        console.log(this.props)
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.grid_size !== prevProps.grid_size){
            this.draw_board()
        }
    }

    //Subcomponent Callbacks BEGIN
    sideBoardCallBack = (increment : number) => {
        let new_move_number = this.state.move_number + increment
        new_move_number = Math.max(0, new_move_number)
        new_move_number = Math.min(new_move_number, this.state.pieces.length -1)
        this.setState(() => ({
            move_number : new_move_number
        }), () => {
            this.draw_board()
        }); 
    }

    gameOverClose = () =>{
        this.props.socket.emit("leave_room", {room : this.props.room_name})
        this.setState(() => ({
            game_over_message : ''
        }), () => {
            this.draw_board()
        }); 
    }

    lower_bar_call_back = (e : React.MouseEvent) => {
        let ans : string | undefined = undefined
        const element = e.target as HTMLElement
        if (element.classList.contains('rules-button') || element.classList.contains('rules')){
            ans = 'rules'
        } else if (element.classList.contains('crypto-button') || element.classList.contains('crypto')){
            ans = 'crypto'
        } else if (element.classList.contains('business-button') || element.classList.contains('business')){
            ans = 'business'
        }
        this.setPageOpen(ans)
        return ans
    }

    move_call_back = (data : any) =>{
        console.log("Move Call Back")
        const move = this.state.pieces.length - 1
        this.setState(() => ({
            move_number : move
        }), () => {
            this.draw_board()
        });  
        const boardState = this.deepCopy(move)  
        const copy = this.deepCopy(move) 
        const move_data = {
            old_x : 7-data.old_x,
            old_y : 7-data.old_y,
            new_x : 7-data.new_x,
            new_y : 7-data.new_y
        }
        const currentPiece = this.state.pieces[move].find(p => p.x === move_data.old_x && p.y === move_data.old_y)
        if (currentPiece){
            const enemy_team = this.props.user_team === TeamType.WHITE ? TeamType.BLACK : TeamType.WHITE 
            const result = possible_pieces_with_king(move_data.old_x, move_data.old_y, move_data.new_x, move_data.new_y, 
                                                            currentPiece.SuperPiece, copy, this.state.active_player, this.state.captured,
                                                            this.state.captured_white_pieces, this.state.captured_black_pieces, 
                                                            this.state.white_promoted, this.state.black_promoted, this.props.user_team)
            const possible_pieces = result.possible_pieces
            this.make_move(move_data.old_x, move_data.old_y, move_data.new_x, move_data.new_y, possible_pieces, boardState, enemy_team)
        }
        this.setActivePiece(null);
        this.draw_board()
        this.forceUpdate()
    }

    take_call_back = (data : any) => {
        this.choosePieceCallBack(piece_converter(data.piece_taken), false)
    }

    dc_callback = (data : any) => {
        let team = data.team
        let message = team === 'white' ? "Black wins by disconnect" : "White wins by disconnect"
        this.setState({
            is_game_over : true,
            game_over_message : message
        })
        this.props.socket.emit("game_over", {room : this.props.room_name})
    }
    
    game_over_callback = (data : any) => {
        this.props.socket.emit("game_over", {room : this.props.room_name})
    }

    settings_call_back = (s : string) => {
        this.setState(() => ({
            skin : s
        }), () => {
            this.draw_board()
        }); 
    }

    choosePieceCallBack = (p : PieceType, should_broadcast : boolean = true) => {
        let white_array : string [] = []
        let black_array : string [] = []
        if (this.state.move_number + 1 === this.state.pieces.length){
            if (this.state.active_player === TeamType.WHITE){
                white_array.push(p)
            }
            else{
                black_array.push(p)
            }
            let dummy_history_b = [...this.state.captured_black_history]
            let dummy_history_w = [...this.state.captured_white_history]
            let last_move = dummy_history_w.length
            dummy_history_b[this.state.move_number] = this.state.captured_black_pieces.concat(black_array)
            dummy_history_w[this.state.move_number] = this.state.captured_white_pieces.concat(white_array)
            this.setState((prevState) => ({
                captured : null,
                captured_white_pieces : prevState.captured_white_pieces.concat(white_array),
                captured_black_pieces : prevState.captured_black_pieces.concat(black_array),
                captured_black_history : dummy_history_b,
                captured_white_history : dummy_history_w
            }), 
            () => {
                const reduced_board_state = fullReduction(this.state.pieces[this.state.move_number], this.state.captured_black_pieces, 
                                            this.state.captured_white_pieces, this.state.active_player, 
                                            this.state.white_promoted, this.state.black_promoted, false, 
                                            this.props.user_team)
                reduced_board_state.forEach(p => {p.SuperPiece.setURL(this.state.skin, this.props.user_team)})
                this.draw_board()
            }); 
            let s = piece2string(p)
            if (should_broadcast){
                this.props.socket.emit("piece_chosen", {piece_taken : s, room : this.props.room_name})
            }
        }
        this.setCaptured(null)
    }
    //Subcomponent Callbacks END

    // State Updates BEGIN 
    setActivePiece(p : HTMLElement | null){
        this.setState({
            active_piece : p
          });
    }

    set_time(){
        // Make sure games are synced
        this.props.socket.emit("up_to_date_check", this.state.move_history, (response : any) =>{
            let num_server_moves = response.moves.length 
            let team = num_server_moves % 2 === 0 ? TeamType.WHITE : TeamType.BLACK 
            if (response.moves.length - 1 === this.state.move_history.length && this.props.mode !== 'Solo' && this.props.user_team !== team){
                this.move_call_back(response.moves[num_server_moves - 1])
            } else if (response.moves.length + 1 === this.state.move_history.length && this.props.mode !== 'Solo' && this.props.user_team !== team){
                let new_move = this.state.move_history[num_server_moves]
                let data = {old_x : new_move.starting_square?.x, old_y : new_move.starting_square?.y, 
                            new_x : new_move.ending_square?.x, new_y : new_move.ending_square?.y, room : this.props.room_name}
                this.props.socket.emit("move_made", data, (response : any) =>{})
            }
        }
        )
        if(this.state.clock.state.remainingTime[0] === 0){
            this.setState({
                is_game_over : true,
                game_over_message : "Black wins on time."
            })
            this.props.socket.emit("game_over", {room : this.props.room_name})
        }
        if(this.state.clock.state.remainingTime[1] === 0){
            this.setState({
                is_game_over : true,
                game_over_message : "White wins on time."
            })
            this.props.socket.emit("game_over", {room : this.props.room_name})
        }
        this.setState({
            time : [this.state.clock.state.remainingTime[0], this.state.clock.state.remainingTime[1]]
          });
    }

    setCaptured( p : Piece | null){
        this.setState(() => ({
            captured : p
        }), () => {
            this.draw_board()
            this.forceUpdate()
        }); 
    }

    setPageOpen( page? : string){
        this.setState(() => ({
            page_open : page
        }), () => {
            this.forceUpdate()
        }); 
    }

    setGridX(x : number){
        this.setState({
            grid_x : x
          });
    }


    setGridY(y : number){
        this.setState({
            grid_y : y
          });
    }

    setGameOver(is_checkmate : boolean, team: TeamType){
        if (is_checkmate){
            let message = team === TeamType.WHITE ? "White wins by checkmate." : "Black wins by checkmate."
            this.props.socket.emit("game_over", {room : this.props.room_name})
            this.setState({
                is_game_over : true,
                game_over_message : message
            })
            this.state.clock.pause()
        } else if (this.state.move_number > 200){
            let message = "Black Wins by 100 move rule."
            this.props.socket.emit("game_over", {room : this.props.room_name})
            this.setState({
                is_game_over : true,
                game_over_message : message
            })
        }
    }

    addMove(nbs : Piece[], old_x : number, old_y : number, new_x : number, new_y : number){
        const moves : Piece[][] = [nbs]
        const starting_square = {x: old_x, y: old_y}
        const ending_square = {x: new_x, y : new_y};
        const new_move = {starting_square, ending_square}
        this.setState((prevState) => ({
            move_history : prevState.move_history.concat(new_move),
            pieces: prevState.pieces.concat(moves),
            captured_white_history : prevState.captured_white_history.concat([prevState.captured_white_pieces]),
            captured_black_history : prevState.captured_black_history.concat([prevState.captured_black_pieces]),
            move_number : prevState.move_number + 1
        }), () => {
            this.draw_board()
        }); 
    }

    switchPlayer(){
    const opponent = this.state.active_player === TeamType.WHITE ? TeamType.BLACK : TeamType.WHITE
    let first_part = opponent === TeamType.WHITE ? "White To " : "Black To " 
    this.setState({
        active_player : opponent,
        message : first_part
        })
    }
    
    playAudio(audio_id: number = 1) {
        const audioEl = document.getElementsByClassName("audio-element")[audio_id]
        const noise = audioEl as HTMLAudioElement
        noise.play()
      }

    handlePromotion(promoted : boolean){
        if (promoted){
            if (this.state.active_player === TeamType.WHITE){
                this.setState(prevState => ({
                    white_promoted : prevState.white_promoted + 1
                }), () => {
                    this.draw_board()
                }); 
            } else {
                this.setState(prevState => ({
                    black_promoted : prevState.black_promoted + 1
                }), () => {
                    this.draw_board()
                });
            }
        }
    }
    // State Updates END

    // Component functions BEGIN
    draw_board() {
        this.state.pieces[this.state.move_number].forEach(p => {p.SuperPiece.setURL(this.state.skin, TeamType.WHITE)})
        this.state.pieces[this.state.move_number].forEach(p => {p.SuperPiece.setURL(this.state.skin, TeamType.BLACK)})
        this.setState({
            board : this.get_board(this.state.pieces[this.state.move_number])
      });
    }


    deepCopy(move : number) {
        const oldBoardState : Piece[] = [];
        this.state.pieces[move].forEach(p => oldBoardState.push({SuperPiece : new SuperPiece([...p.SuperPiece.subPieces], p.SuperPiece.team), 
                                                x : p.x,  y : p.y, promoted: p.promoted}))
        return oldBoardState
    }

    get_board(pieces : Piece []){
        let board = []
        let move = this.state.move_history[this.state.move_number]
        for (let j = AXIS.length - 1; j >= 0; j-- ){
            for (let i = 0; i < AXIS.length; i++ ) {
                const number = i + j;
                const piece = pieces.find(p => p.x === i && p.y === j) 
                let image = piece ? piece.SuperPiece.img_url : undefined
                board.push(<Tile key = {`${i}, ${j}`} image = {image} x = {i} y = {j} grid_size = {this.props.grid_size}
                            last_square = {move.starting_square} new_square = {move.ending_square} piece = {piece}/>);
            }
        }
        return board
    }

    revert(new_message : string){
        if (new_message !== ''){
            this.playAudio(2)
        }
        if (this.state.active_piece){
            this.state.active_piece.style.position = 'relative';
            this.state.active_piece.style.removeProperty('left');
            this.state.active_piece.style.removeProperty('top');
            this.state.active_piece.style.removeProperty('height')
            this.state.active_piece.style.removeProperty('width')
            this.draw_board()
        }
        this.props.message_callback(new_message)
    }

    grabPiece(e: React.MouseEvent){
        const chessboard = this.state.chessboardRef.current
        const element = e.target as HTMLElement
        if (element.classList.contains('chess-piece') && chessboard){
            const rect = chessboard.getBoundingClientRect()
            this.setGridX(Math.floor((e.clientX - rect.left)/this.props.grid_size));
            this.setGridY(Math.abs(Math.ceil((e.clientY - rect.top - 8 * this.props.grid_size)/this.props.grid_size)));
            var el = document.getElementsByTagName("html")[0]
            var scroll_amount = el.scrollTop
            const x = e.clientX - 0.95*this.props.grid_size/2;
            const y = e.clientY + scroll_amount - 0.95*this.props.grid_size/2;
            element.style.position = 'absolute'; 
            element.style.left = `${x}px`;
            element.style.top = `${y}px`;
            element.style.width = `${this.props.grid_size * 0.95}px`
            element.style.height = `${this.props.grid_size * 0.95}px`
            this.setActivePiece(element);
        }
    
    }
    // Places the center of the active piece beneath the cursor
    movePiece(e: React.MouseEvent){
        const chessboard = this.state.chessboardRef.current
        if (this.state.active_piece && chessboard){
            var el = document.getElementsByTagName("html")[0]
            var scroll_amount = el.scrollTop
            const x = e.clientX - 0.95*this.props.grid_size/2;
            const y = e.clientY + scroll_amount - 0.95*this.props.grid_size/2;
            this.state.active_piece.style.position = 'absolute'; 
            this.state.active_piece.style.left = `${x}px`;
            this.state.active_piece.style.top = `${y}px`;
        }
    }

    dropPiece(e: React.MouseEvent){
        const chessboard = this.state.chessboardRef.current;
        if (this.state.active_piece && chessboard && !this.state.is_game_over && (this.props.user_team === this.state.active_player || this.props.mode === 'Solo')){
            const rect = chessboard.getBoundingClientRect()
            const x = Math.floor((e.clientX - rect.left)/this.props.grid_size);
            const y = Math.abs(Math.ceil((e.clientY - rect.top - 8 * this.props.grid_size)/this.props.grid_size))
            const move = this.state.pieces.length - 1 
            const currentPiece = this.state.pieces[move].find(p => p.x === this.state.grid_x && p.y === this.state.grid_y)
            if (currentPiece){
                const boardState = this.deepCopy(move)  
                const copy = this.deepCopy(move) 
                const result = possible_pieces_with_king(this.state.grid_x, this.state.grid_y, x, y, currentPiece.SuperPiece, 
                                                        copy, this.state.active_player, this.state.captured,
                                                        this.state.captured_white_pieces, this.state.captured_black_pieces, 
                                                        this.state.white_promoted, this.state.black_promoted, this.props.user_team); 
                const possible_pieces = result.possible_pieces
                const message = result.message
                if (possible_pieces.length > 0 && this.state.move_number + 1 === this.state.pieces.length){  
                        let data = {old_x : this.state.grid_x, old_y : this.state.grid_y, new_x : x, new_y : y, room : this.props.room_name}
                        this.props.socket.emit("move_made", data, (response : any) =>{
                                                                if (response !== null){
                                                                    this.make_move(this.state.grid_x, this.state.grid_y, x, y, possible_pieces, boardState, this.props.user_team)
                                                                }
                                                                else {
                                                                    this.revert("Move didn't reach server.  Try Again.")
                                                                }
                                                            })
                } else { 
                    if (this.state.move_number + 1 !== this.state.pieces.length) {
                        this.revert("Not the current position.")
                    } else {
                        this.revert(message)
                    }
                }
            } else{
                this.revert('No piece selected')
            }
        } else{
            if (!this.state.active_piece){
                this.revert('No Piece Selected')
            } else {
                this.revert('Not your turn')
            }
        }
        this.setActivePiece(null);
        this.draw_board()
        
        }



    make_move(new_x : number, new_y : number, x : number, y : number, possible_pieces : PieceType[], boardState : Piece [], team : TeamType){
        const new_state = executeMove(new_x, new_y, x, y, possible_pieces, this.state.active_player, boardState)
        const promotions = promotion_array(new_state.promoted, this.state.white_promoted, this.state.black_promoted, team)
        this.handlePromotion(new_state.promoted)
        const reduced_board_state = fullReduction(new_state.board_state,this.state.captured_black_pieces, this.state.captured_white_pieces, 
                                                this.state.active_player, promotions[0], promotions[1], true,
                                                this.state.active_player)
        const enemy_team = this.state.active_player === TeamType.WHITE ? TeamType.BLACK : TeamType.WHITE  
        this.setCaptured(new_state.taken_piece)
        console.log("TEAMS" , enemy_team, this.state.active_player)
        console.log(reduced_board_state)
        console.log(this.state.captured_black_pieces)
        console.log(this.state.captured_white_pieces)
        const checkmate = is_checkmate(reduced_board_state, enemy_team, this.state.captured_white_pieces, 
                this.state.captured_black_pieces, promotions[0], promotions[1], this.props.user_team)
        console.log(checkmate)
        this.setGameOver(checkmate, this.state.active_player)
        this.addMove(reduced_board_state, new_x, new_y, x, y)
        this.switchPlayer()
        let player_number : 0 | 1 = this.state.active_player === TeamType.WHITE ? 0 : 1
        this.state.clock.push(player_number)
        this.props.message_callback('')
        if (new_state.taken_piece === null){
            this.playAudio(1)
        } else {
            this.playAudio(0)
        }
    }


    render() {
      return <div className='board-and-sideboard'>
                <AudioFiles/>
                <div className = 'chessboard'
                        ref = {this.state.chessboardRef} 
                        onMouseUp = {e => this.dropPiece(e)} 
                        onMouseMove = {e => this.movePiece(e)} 
                        onMouseDown = {e => this.grabPiece(e)}>
                        {this.state.board}
                </div>
                <SideBoard 
                    grid_size={this.props.grid_size}
                    skin = {this.state.skin}
                    black_captured = {this.state.captured_black_history[this.state.move_number]}
                    white_captured = {this.state.captured_white_history[this.state.move_number]}
                    call_back = {this.sideBoardCallBack}
                    choose_piece_call_back = {this.choosePieceCallBack}
                    captured = {this.state.captured}
                    is_game_over = {this.state.is_game_over}
                    user_team = {this.props.user_team}
                    current_move = {this.state.move_number}
                    total_moves = {this.state.pieces.length}
                    time = {this.state.clock.state.remainingTime}
                    mode = {this.props.mode}
                    active_player = {this.state.active_player}
                ></SideBoard>
                    <DisplayWinner 
                        close = {this.gameOverClose}
                        message = {this.state.game_over_message}
                        play_again_callback = {this.props.play_again_callback}
                    ></DisplayWinner>
                <MoveListener socket = {this.props.socket} 
                            move_call_back = {this.move_call_back} 
                            take_call_back = {this.take_call_back}
                            dc_callback = {this.dc_callback}
                            game_over_callback = {this.game_over_callback}/>
                </div>
    }
  }


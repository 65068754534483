import React, { Component } from 'react';
import './ChoosePiece.css';
import  {Piece, PieceType, TeamType } from '../../Constants/constants'
import PeiceButton from '../PieceButton/PieceButton';



type State = {
    piece_array : JSX.Element[],
    column_css : string,
    text_color? : string,
    bg_color? : string,
    enemy_team? : TeamType,
    grid_row_start? : number, 
    grid_row_end? : number
}


type Props = {
    captured_piece : Piece | null,
    call_back : (piece : PieceType, should_broadcast? : boolean) => void,
    is_game_over : boolean,
    skin : string,
    user_team : TeamType,
    mode : string
}


export default class SideBoard extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.get_pieces.bind(this) 
        this.getEnemyTeam.bind(this) 
        this.state = {
            piece_array : [],
            column_css : '',
        }   
    }
    componentDidMount()
    {
        if (this.props.captured_piece){
            let subpieces = this.props.captured_piece.SuperPiece.subPieces
            if (subpieces.length < 2 || (subpieces.length === 2 && subpieces[0] === 'k')){
                let index = subpieces.length < 2 ? 0 : 1
                this.props.call_back(this.props.captured_piece.SuperPiece.subPieces[index])
            }
            else {
                this.get_pieces()
            }
        }
        else{
                this.get_pieces()
        }
    }

    getEnemyTeam(){
        if (this.props.user_team === undefined){
            return undefined
        } else {
            const enemy_team = this.props.user_team === TeamType.WHITE ? TeamType.BLACK : TeamType.WHITE
            return enemy_team
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.captured_piece !== prevProps.captured_piece){
            {
                if (this.props.captured_piece){
                    let subpieces = this.props.captured_piece.SuperPiece.subPieces
                    if (subpieces.length < 2 || (subpieces.length === 2 && subpieces[0] === 'k')){
                        let index = subpieces.length < 2 ? 0 : 1
                        this.props.call_back(this.props.captured_piece.SuperPiece.subPieces[index], false)
                    }
                    else {
                        this.get_pieces()
                    }
                }
                else{
                        this.get_pieces()
                }
            }
        }
    }


    get_pieces(){
        let dummy_array : JSX.Element[] = [] 
        let dummy_string = '0.25fr '
        const team = this.props.captured_piece?.SuperPiece.team
        const dir = team === TeamType.WHITE ? 'white_pieces' : 'black_pieces';
        let num = 1
        if (this.props.captured_piece){
            for (let i = 0;  i < this.props.captured_piece.SuperPiece.subPieces.length; i++ ){
                const url = this.props.captured_piece.SuperPiece.subPieces[i]
                num += 1
                if (url !== 'k'){
                    let img_url = `assets/${dir}/solo/${this.props.skin}/${url}.png`
                    dummy_array.push(<PeiceButton key = {`${i}`} image_url = {img_url} num = {num} p = {url} pass_back = {this.props.call_back}/>)
                    dummy_string += '1fr '
                } else{
                    num -= 1
                }
            }
    }
    dummy_string += '0.25fr'
    var row_start : number = 0
    var row_end : number = 0
    if (this.props.mode === 'Solo' && this.props.user_team !== this.props.captured_piece?.SuperPiece.team){
        // solo game black captured piece
        row_start = 4
        row_end = 5
    } else {
        row_start = 12
        row_end = 13
    }
    this.setState({
        piece_array : dummy_array,
        column_css : dummy_string,
        text_color : this.props.captured_piece?.SuperPiece.team === TeamType.WHITE ? 'white' : 'black',
        bg_color : this.props.captured_piece?.SuperPiece.team === TeamType.WHITE ? 'silver' : 'silver',
        grid_row_end : row_end,
        grid_row_start : row_start
    })
    }
    
    render() {
        if (!this.props.captured_piece || this.props.captured_piece.SuperPiece.subPieces.length < 2
            || this.props.is_game_over || (this.props.captured_piece.SuperPiece.subPieces.length === 2 && 
                                            this.props.captured_piece.SuperPiece.subPieces[0] === 'k')
                                            || (this.props.user_team !== this.props.captured_piece.SuperPiece.team && this.props.mode !== 'Solo')){
         return null
        }
        else{
            return <div className='captured-piece-popup' style = {{backgroundColor : this.state.bg_color, color : this.state.text_color, 
                                                                    gridRowStart : this.state.grid_row_start, gridRowEnd : this.state.grid_row_end}}>
                        {/* <div className = 'captured-info' >A piece of yours was captured. <br/> Which piece was it?</div> */}
                        <div className='button-row' style = {{gridTemplateColumns : this.state.column_css}}>
                            {this.state.piece_array}
                        </div>
                    </div>
        }
        }
    }
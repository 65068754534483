import React, { Component } from 'react';
import PieceImage from '../PieceImage/PieceImage';
import './Sideboard.css';
import {custom_sort} from '../../Referee/sorter'
import ChoosePiece from '../ChoosePiece/ChoosePiece';
import  {Piece, PieceType, TeamType} from '../../Constants/constants'
import ForwardBackArrows from '../ForwardBackwardArrows/ForwardBackwardArrows';
import arrow_url from "../../BG_Images/arrow.png";
import gray_url from "../../BG_Images/gray_arrow.png";

type State = {
    arrow_url : string, 
    white_JSX : JSX.Element[],
    black_JSX : JSX.Element[],
}


type Props = {
    grid_size : number
    black_captured : (string | null)[],
    white_captured : (string | null)[],
    captured : Piece | null,
    choose_piece_call_back : (piece : PieceType) => void,
    call_back : (move : number) => void,
    new_game? : () => void,
    is_game_over : boolean,
    skin : string,
    user_team : TeamType,
    time : any[],
    current_move : number,
    total_moves : number,
    mode : string,
    active_player : TeamType
}


export default class SideBoard extends Component<Props, State> {
    private enemy_team : TeamType;
    constructor(props : Props) { 
        super(props)
        this.state = {
            arrow_url: `assets/arrow.png`,
            white_JSX : [],
            black_JSX : [],
         }
         this.enemy_team = this.props.user_team === TeamType.WHITE ? TeamType.BLACK : TeamType.WHITE 
    }

    componentDidMount()
    {
        this.get_pieces()
    }

    setTeamText(){
        var ans = ""
        if (this.props.user_team !== undefined){
            ans = this.props.user_team === TeamType.WHITE ?  "Playing as White" : "Playing as Black"
            return <div className='team-text-box'>
                <div className='text'>{ans}</div>
            </div>
        } else {
            return null
        }
    }

    get_style( captured_box : TeamType){
        const s = captured_box === this.props.user_team ? {gridRowStart : 14, gridRowEnd : 15} : {gridRowStart : 2, gridRowEnd : 3}
        return s
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.black_captured !== prevProps.black_captured || this.props.white_captured !== prevProps.white_captured
            || this.props.skin !== prevProps.skin || this.props.captured != prevProps.captured || this.props.time !== prevProps.time){
            this.get_pieces() 
        }
    }

    format_time(millisec : number) {
        var seconds = (millisec / 1000).toFixed(0);
        var secs = (millisec / 1000)
        var mins = Math.floor(secs / 60);
        var hrs = 0;
        var hours = ""
        var minutes = ""
        if (mins > 59) {
            hrs = Math.floor(mins / 60);
            hours = hrs.toString();
            mins = mins - (hrs * 60);
            minutes = (mins >= 10) ? mins.toString() : "0" + mins.toString();
        }

        secs = Math.floor(secs % 60);
        seconds = (secs >= 10) ? secs.toString() : "0" + secs.toString();
        if (hrs !== 0) {
            return hours + ":" + minutes + ":" + seconds;
        }
        return mins.toString() + ":" + seconds;
    }

    get_pieces(){
        let white_array : JSX.Element[] = [] 
        let black_array : JSX.Element[] = [] 
        const sorted_white_pieces = custom_sort(this.props.white_captured, ["p", "n", "b", "r", "q"])
        const sorted_black_pieces = custom_sort(this.props.black_captured, ["p", "n", "b", "r", "q"])
        for (let i = 0;  i < sorted_white_pieces.length; i++ ){
            if (sorted_white_pieces[i]){
                const url = sorted_white_pieces[i]
                let img_url = ''
                if (this.props.skin === 'default'){
                    img_url = `assets/white_pieces/solo/${this.props.skin}/${url}.png`
                } else {
                    img_url = `assets/white_pieces/solo/${this.props.skin}/${url}.png`
                }
                white_array.push(<PieceImage key = {`${i} white`} image_url = {img_url} num = {i}/>)
            }
        }
        for (let i = 0;  i < sorted_black_pieces.length; i++ ){
            if (sorted_black_pieces[i]){
                const url = sorted_black_pieces[i]
                let img_url = ''
                if (this.props.skin === 'default'){
                    img_url = `assets/black_pieces/solo/${this.props.skin}/${url}.png`
                } else {
                    img_url = `assets/black_pieces/solo/${this.props.skin}/${url}.png`
                }
                black_array.push(<PieceImage key = {`${i} black`} image_url = {img_url} num = {i}/>)
            }
        }
    this.setState({
        white_JSX : white_array,
        black_JSX : black_array
    })
    }

    render() {
    return  <div className='sideboard'>
                <div className='white-captured' style = {this.get_style(TeamType.WHITE)}>
                    {this.state.white_JSX}
                </div>
                <div className='black-captured' style = {this.get_style(TeamType.BLACK)}>
                    {this.state.black_JSX}
                </div>
                <div className='upper-divider'></div>
                <div className='black-clock' style = {{fontSize : this.props.grid_size/1.5}}>
                    <div className='black-clock-text'>
                        {this.format_time(this.props.time[this.enemy_team])}
                    </div>
                    <div className='black-clock-bottom' style = {{display : this.props.active_player === this.props.user_team || this.props.is_game_over ? 'none' : 'grid' }}></div>
                </div>
                <div className='arrows'>
                    <ForwardBackArrows callback = {this.props.call_back} length = {this.props.total_moves} 
                                        current_num = {this.props.current_move} divisor = {2}/>
                </div>
                <div className='white-clock' style = {{fontSize : this.props.grid_size/1.5}}> 
                    <div className='white-clock-top' style = {{display : this.props.active_player !== this.props.user_team || this.props.is_game_over ? 'none' : 'grid' }}></div>                   
                    <div className='white-clock-text'>
                        {this.format_time(this.props.time[this.props.user_team])}
                    </div>
                </div>
                <div className='lower-divider'></div>
                <ChoosePiece 
                    skin = {this.props.skin}
                    is_game_over = {this.props.is_game_over}
                    captured_piece = {this.props.captured} 
                    call_back = {this.props.choose_piece_call_back}
                    user_team = {this.props.user_team}
                    mode = {this.props.mode}
                ></ChoosePiece>
            </div>
    }
}
import React, { Component } from 'react';
import './Button.css'

type State = {

}

type Props = {
    name : string,  
    style? : any,
    handle_click : any,
    class_name : string
}


export default class Button extends Component<Props, State> {
    constructor(props : Props) {
        super(props);
        this.handle_click.bind(this)
    }

    handle_click(e : React.MouseEvent) {
        this.props.handle_click(this.props.name)
    }
    
    hover(e : React.MouseEvent){
        let element = e.target as HTMLElement
        if (element.classList.contains('button-box')){
            this.set_hover_style(element) 
        } else if (element.classList.contains('inner-button')){
            let parent = element.parentElement as HTMLAreaElement
            this.set_hover_style(parent) 
        }
    }

    unhover(e : React.MouseEvent){
        let element = e.target as HTMLElement
        if (element.classList.contains('button-box')){
            this.set_unhover_style(element) 
        } else if (element.classList.contains('inner-button')){
            let parent = element.parentElement as HTMLAreaElement
            this.set_unhover_style(parent) 
        }
        //child.style.color = this.props.style.color
    }

    set_unhover_style(element : HTMLElement){
        let style = this.props.style
        element.style.backgroundColor = style.background_color 
        element.style.color = style.color
        element.style.width = style.width
        element.style.height = style.height
    }

    set_hover_style(element : HTMLElement){
        let style = this.props.style
        element.style.backgroundColor = style.hover_background_color 
        element.style.color = style.hover_color
        element.style.width = style.hover_width
        element.style.height = style.hover_height
    }


    render() {
        return <div className={this.props.class_name}>
                    <div className='bounding-box'>
                        <div className='button-box' onClick={(e) => this.handle_click(e)} 
                                                onMouseEnter = {(e) => this.hover(e)} 
                                                onMouseLeave={(e) => this.unhover(e)}
                                                style = {this.props.style?.style}>
                        <div className='inner-button' onClick={(e) => this.handle_click(e)} >
                            {this.props.name}
                        </div>
                    </div>
                </div>
            </div>

    }
}
import SuperPiece from './superPiece';

export const HORIZONTAL_AXIS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
export const VERTICAL_AXIS = [1,2,3,4,5,6,7,8];
export const AXIS = [0,1,2,3,4,5,6,7];
export const GRID_SIZE = 100


export interface Piece {
    SuperPiece : SuperPiece;
    x : number; 
    y : number; 
    taken? : boolean;
    promoted? : boolean;
}

export interface Square {
    x : number; 
    y : number; 
}

export interface Move{
    starting_square? : Square,
    ending_square? : Square 
}

export interface option {
    value : string, 
    label : string
}

export type GameType = {
    game_options : option[],
    time_options : option[],
    time_control : string | null,
    game_type : string | null,
    en_passant : boolean,
    castling : boolean,
    random_capture : boolean,
    decoherence : boolean,
    play_button? : JSX.Element,
    game_ready : boolean | null,
    room_name : string,
    user_team : string
}



export enum TeamType{
    WHITE,
    BLACK
}

export enum PieceType {
    PAWN = 'p',
    BISHOP = 'b', 
    NIGHT = 'n', 
    ROOK = 'r',
    QUEEN = 'q',
    KING = 'k',
    PROMOTED = 'm'
}

export function initializeBoardState(user_team : TeamType) : Piece [][]{
    const moves : Piece[][] = []
    const state : Piece[] = []
    const enemy_team = user_team === TeamType.WHITE ? TeamType.BLACK : TeamType.WHITE
    for (let p = 0; p < 2; p ++){
        for (let i = 0; i < AXIS.length; i++){
            state.push({SuperPiece : new SuperPiece([PieceType.KING, PieceType.QUEEN, PieceType.ROOK, 
                                    PieceType.BISHOP, PieceType.NIGHT, PieceType.PAWN], user_team), x : i, y : 0 + p, promoted : false})
            state.push({SuperPiece : new SuperPiece([PieceType.KING, PieceType.QUEEN, PieceType.ROOK, 
                                    PieceType.BISHOP, PieceType.NIGHT, PieceType.PAWN], enemy_team), x : i, y : 6 + p, promoted : false})
        }
    }
    moves.push(state)
    return moves
}


export function index_converter(p : PieceType) : number{
    switch (p){
        case PieceType.KING : {
            return 0;
        }
        case PieceType.QUEEN : {
            return 1;
        }
        case PieceType.ROOK : {
            return 2;
        }
        case PieceType.BISHOP : {
            return 3;
        }
        case PieceType.NIGHT : {
            return 4;
        }
        case PieceType.PAWN : {
            return 5;
        }
    }
    return -1
}


export function piece_converter(p : string) : PieceType{
    switch (p){
        case 'k' : {
            return PieceType.KING;
        }
        case 'q' : {
            return PieceType.QUEEN;
        }
        case 'r' : {
            return PieceType.ROOK;
        }
        case 'b' : {
            return PieceType.BISHOP;
        }
        case 'n' : {
            return PieceType.NIGHT;
        }
        case 'p' : {
            return PieceType.PAWN;
        }
    }
    return PieceType.PAWN
}

export function string_index_converter(p : string) : number{
    switch (p){
        case 'k' : {
            return 0;
        }
        case 'q' : {
            return 1;
        }
        case 'r' : {
            return 2;
        }
        case 'b' : {
            return 3;
        }
        case 'n' : {
            return 4;
        }
        case 'p' : {
            return 5;
        }
    }
    return -1
}


export function piece2string(p : PieceType) : string{
    switch (p){
        case PieceType.KING : {
            return 'k';
        }
        case PieceType.QUEEN : {
            return 'q';
        }
        case PieceType.ROOK : {
            return 'r';
        }
        case PieceType.BISHOP : {
            return 'b';
        }
        case PieceType.NIGHT : {
            return 'n';
        }
        case PieceType.PAWN : {
            return 'p';
        }
        case PieceType.PROMOTED : {
            return 'm';
        }
    }
}

export const about_me :string = ` # About Me

> *Dr. Taylor is a bonafide tour de force. A bastion of humility who would never tell you about when he completed an Iron Man 
with no training, or taught himself how to solve a Rubiks cube.  Unafraid to face the unknown, he has hitch-hiked across the nation, 
rode his single speed through the snow and rain from Seattle to Boise and spent months on end living in the wilderness.  Despite his proclivity 
for counter culture, he found time to earn a PhD in Quantum Computing, went viral on reddit, and ran a small business setting up 
Ethereum mining facilites.  Some wonder how he has the time, others speculate that he may be two places at once, all know that his
secret has something to do with his sense of humor.*
    
> **-** Jasper

A long time creator of artisanal one of a kind board games, Quantum Chess is my first foray into the digital gaming 
world, 3 months ago I had never used javascript/typescript/react and have since poured my time, focus, and soul into mastering the skills 
necessary to bring this site to fruition.  If this project is funded, you can rest assured that I will work obsessively on it 
until it reaches its full potential.

# Contact Me 

If you are interested in the project in any way please reach me at [jaspertaylor15@protonmail.com](mailto:jaspertaylor15@protonmail.com)

`
import { Component } from 'react';
import Preload from '../../Components/Preload/Preload';
import WordShow from '../../Components/WordShow/WordShow';
import HomeScreen from './HomeScreen/HomeScreen';
import './LandingPage.css'
import { SOME_PICTURES } from '../../Constants/Pictures'

const welcome_message =  [  `no boring draws`,
                            `no opening theory`,
                            `no engines`,
                            `multicolored bishops`, 
                            `entangled pieces`,
                            `real quantum behavior`,
                            `crazy new tactics`,
                            `2000 IQ plays`]



type State = {

}

type Props = {
    grid_size : number
}


export default class LandingPage extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {

        }
    }

    render() {
        return <div className='landing-page'>
                    <div className='banner'  style = {{backgroundImage : `url(assets/landing_page/quantum_chess.svg)`}}></div>
                    <div className='motto' style = {{fontSize : `${this.props.grid_size/2}px`}}>
                        <div className='multiverse'>Welcome to chess in the multiverse</div>
                        <WordShow words = {welcome_message}></WordShow>
                        <div className='f2p'>Free 2 Play. Play 2 Earn. Earn to Own.</div>
                    </div>
                    <Preload loaded_jsx = {[<HomeScreen key = {1} grid_size={this.props.grid_size}/>]} 
                            layout = 'bottom-slides'
                            images={SOME_PICTURES}/>
                </div>

    }
}




{/* <div className="ticker-wrap">
<div className="ticker1">
    <div className="item">{welcome_message[0]}</div>
</div>
<div className="ticker2">
    <div className="item">{welcome_message[1]}</div>
    <div className="item">{welcome_message[2]}</div>
    <div className="item">{welcome_message[3]}</div>
    <div className="item">{welcome_message[4]}</div>
</div>
<div className="ticker3">
    <div className="item">{welcome_message[5]}</div>
    <div className="item">{welcome_message[6]}</div>
    <div className="item">{welcome_message[7]}</div>
</div>
</div> */}
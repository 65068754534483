export default function AudioFiles() {
    return  <div className="audio-files">               
                <audio className="audio-element">
                    <source src='assets/sounds/thud3.mp3'></source>
                </audio>
                <audio className="audio-element">
                    <source src='assets/sounds/kaplink.mp3'></source>
                </audio>
                <audio className="audio-element">
                    <source src='assets/sounds/error.mp3'></source>
                </audio>
            </div>
}
import React, { Component } from 'react';
import Toggle from 'react-toggle'
import './Toggle.css'

type State = {
    value: boolean,
}

type Props = {
    callback: any,
    class_name: string,
    init_value: boolean,
    label: string,
    grid_size : number
}


export default class Button extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: this.props.init_value
        }
        this.handle_change.bind(this)
    }

    handle_change = () => {
        let new_value = !this.state.value
        this.setState({
            value: new_value
        }, () => this.forceUpdate())
        this.props.callback(new_value)
    }

    get_toggle_style(num_buttons: number) {
        let dummy_string = ''
        for (let i = 0; i < num_buttons; i++) {
            dummy_string += '1fr '
        }
        return dummy_string
    }

    render() {
        return <div className={this.props.class_name}>
            <div className='toggle-wrapper'>
                <div className='toggle-right-half'>
                    <Toggle
                        defaultChecked={this.state.value}
                        icons={false}
                        onChange={this.handle_change} />
                </div>
                <div className='toggle-left-half'>
                    {this.props.label}
                </div>
            </div>
        </div>
    }
}
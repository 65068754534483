import { TeamType } from "../Constants/constants";

export function applyCustomOrder(arr : string[], desiredOrder : string[]){
    const orderForIndexVals = desiredOrder.slice(0).reverse();
    arr.sort((a, b) => {
      const aIndex = -orderForIndexVals.indexOf(a);
      const bIndex = -orderForIndexVals.indexOf(b);
      return aIndex - bIndex;
    });
  }

export function clean(arr: (string | null)[]) {
    let ans : string[] = []
    for (let i = 0;  i < arr.length; i++ ){
        const x = arr[i]
        if (x){
            ans.push(x)
        }
    }
    return ans   
}



export function custom_sort(arr: (string | null)[], desiredOrder :string[]){
    let ans = clean(arr)
    applyCustomOrder(ans, desiredOrder)
    return ans
}

export function addvector(a : number [], b : number []){
    return a.map((e,i) => e + b[i]);
}

export function promotion_array(promotion : boolean, old_white : number, old_black : number, team : TeamType){
    if (promotion){
        if (team === TeamType.WHITE){
            return [old_white +1, old_black]
        } else {
            return [old_white, old_black + 1]
        }
    } else {
        return [old_white, old_black]
    }
}
import React, { Component } from 'react';
import { interval } from 'rxjs';
import './SlideShow.css'

import { SOME_PICTURES, EXTRA } from '../../Constants/Pictures'


type State = {
    slide_url : string
    intervalId? : NodeJS.Timer
}

type Props = {
    interval? : number
    row : number
    column : number
}


export default class SlideShow extends React.Component <Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {
            slide_url : '',
        }
    }
  
    componentDidMount() {
      const newIntervalId = setInterval(() => {
        this.setState(prevState => {
          return {
            ...prevState,
            slide_url: this.get_new_url(),
          };
        });
      }, 1000);
      this.setState(prevState => {
        return {
          ...prevState,
          intervalId: newIntervalId,
        };
      });
    
    }
  
    componentWillUnmount(){
      clearInterval(this.state.intervalId);
    }
  
    get_new_url(){
      let url = SOME_PICTURES[Math.floor(EXTRA.length + Math.random() * (SOME_PICTURES.length - 1 - EXTRA.length))]
      if (url.includes('solo') && url.includes('white_pieces')){
        return 'white_pieces/sp/kqrbnp.png'
      } else if (url.includes('solo') && url.includes('black_pieces')) {
        return 'black_pieces/sp/kqrbnp.png'
      } else if (url.includes('spf')){
        url = url.replace('spf', 'sp')
      }
      return url
    }

    render() {
      return (
        <div className='slideshow' style = {{backgroundImage : `url(assets/${this.state.slide_url})`,
                                            gridRowStart : this.props.row, gridRowEnd : this.props.row + 1,
                                            gridColumnStart : this.props.column, gridColumnEnd : this.props.column + 1}}></div>
      );
    }
  }
  
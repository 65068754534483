import React, { Component } from 'react';
import './App.css';
import {io, Socket} from 'socket.io-client'
import Router from './Pages/Router/Router';
import "./Fonts/TheLedDisplaySt.woff2"
import "./Fonts/Fenix-Regular.woff2"
// import { Link, ETHTokenType } from '@imtbl/imx-sdk'

var socket : Socket 
if (process.env.NODE_ENV === 'production') {
  socket = io();
} else {
  socket = io("http://localhost:8000");
}


type AppState = {
    grid_size : number,
    height: number, 
    width: number,
} 

type AppProps = {

}


class App extends Component<AppProps, AppState> {
    constructor(props : AppProps) { 
    super(props)
        this.state = {
          height: window.innerHeight, 
          width: window.innerWidth,
          grid_size : this.get_grid_size(),
        }
        this.get_grid_size.bind(this)
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      // Additionally I could have just used an arrow function for the binding `this` to the component...
      window.addEventListener("resize", this.updateDimensions);
      window.addEventListener('contextmenu', function (e) {
        e.preventDefault();
      }, false);
    }

    updateDimensions() {
      this.setState({
        height: window.innerHeight, 
        width: window.innerWidth,
        grid_size : this.get_grid_size(),
      });
    }


    get_grid_size(){
        let wind = {w : window.innerWidth, h : window.innerHeight}
        return Math.min(wind.w/13, wind.h/11)
      }
  // render will know everything!
  render() {
  return <div className='wrapper'>
              <Router grid_size = {this.state.grid_size} socket = {socket} width = {this.state.width} height = {this.state.height}></Router>
           </div>
  }
}

export default App
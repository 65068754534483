import { Piece, TeamType, Square } from '../../Constants/constants'
import './tile.css'

interface Props {
    x : number;
    y : number;
    grid_size : number;
    last_square? : Square;
    new_square? : Square;
    image? : string;
    piece? : Piece;
}

// to add animation
//{{backgroundImage : `url(${image})`, animationName : 'checking', animationDuration : '.5s', animationIterationCount : '0'}}

// WARNING EXTREMELELY UGLY CODE AHEAD

export default function Tile({x, y, grid_size, last_square, new_square, image, piece}: Props){
    let number = x + y
    if ((last_square?.x === x  && last_square?.y === y) || (new_square?.x === x  && new_square?.y === y)){
        if (piece && piece.SuperPiece.team === TeamType.BLACK){
                if (number % 2 === 0){
                        return <div className = 'tile black-tile-alt'>
                            {image && <div style = {{backgroundImage : `url(${image})`, width :`${grid_size}`, height :`${grid_size}`}} className = 'chess-piece opponent-piece'></div>}
                        </div>
                } else{
                    return <div className = 'tile white-tile-alt'> 
                            {image && <div style = {{backgroundImage : `url(${image})`, width :`${grid_size}`, height :`${grid_size}`}} className = 'chess-piece opponent-piece'></div>}
                        </div>
                }
            } else {
                
                if (number % 2 === 0){
                    return <div className = 'tile black-tile-alt'>
                        {image && <div style = {{backgroundImage : `url(${image})`, width :`${grid_size}`, height :`${grid_size}`}} className = 'chess-piece'></div>}
                    </div>
            } else{
                return <div className = 'tile white-tile-alt'> 
                        {image && <div style = {{backgroundImage : `url(${image})`, width :`${grid_size}`, height :`${grid_size}`}} className = 'chess-piece'></div>}
                    </div>
            }
        }
    }  else {
        if (piece && piece.SuperPiece.team === TeamType.BLACK){
                if (number % 2 === 0){
                        return <div className = 'tile black-tile'>
                            {image && <div style = {{backgroundImage : `url(${image})`, width :`${grid_size}`, height :`${grid_size}`}} className = 'chess-piece opponent-piece'></div>}
                        </div>
                } else{
                    return <div className = 'tile white-tile'> 
                            {image && <div style = {{backgroundImage : `url(${image})`, width :`${grid_size}`, height :`${grid_size}`}} className = 'chess-piece opponent-piece'></div>}
                        </div>
                }
            } else {     
                if (number % 2 === 0){
                    return <div className = 'tile black-tile'>
                        {image && <div style = {{backgroundImage : `url(${image})`, width :`${grid_size}`, height :`${grid_size}`}} className = 'chess-piece'></div>}
                    </div>
            } else{
                return <div className = 'tile white-tile'> 
                        {image && <div style = {{backgroundImage : `url(${image})`, width :`${grid_size}`, height :`${grid_size}`}} className = 'chess-piece'></div>}
                    </div>
            }
        }
    }
    
}
import { Component } from 'react';
import { Socket } from 'socket.io-client';
import Play from '../Play/Play'
import Chapters from '../Chapters/Chapters'
import {RULES_NAMES, RULES_CHAPTERS, WP_NAMES, WP_CHAPTERS, RULES_PICTURES, WP_PICTURES, ABOUT_NAMES, ABOUT_CHAPTERS, ABOUT_PICTURES} from '../../Constants/Markdown'
import LandingPage from '../LandingPage/LandingPage';

type State = {

}

type Props = {
    socket : Socket,
    grid_size : number,
    screen_name : string,
    width : number
    height : number
}


export default class Screen extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {

        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.screen_name !== prevProps.screen_name){
            this.forceUpdate()
       }
    }

    render() {
        console.log(this.props.screen_name)
        if (this.props.screen_name === "Home"){
            return <LandingPage grid_size={this.props.grid_size}/>
        } else if (this.props.screen_name=== "Rules"){
            return <Chapters grid_size={this.props.grid_size} 
                            width = {this.props.width} 
                            height = {this.props.height}
                            names = {RULES_NAMES}
                            chapters = {RULES_CHAPTERS}
                            pictures = {RULES_PICTURES}></Chapters>
        } else if (this.props.screen_name === "White Paper"){
            return <Chapters grid_size={this.props.grid_size} 
                            width = {this.props.width} 
                            height = {this.props.height}
                            names = {WP_NAMES}
                            chapters = {WP_CHAPTERS}
                            pictures = {WP_PICTURES}></Chapters>
        }else if (this.props.screen_name === "About Me"){
            return <Chapters grid_size={this.props.grid_size} 
                            width = {this.props.width} 
                            height = {this.props.height}
                            names = {ABOUT_NAMES}
                            chapters = {ABOUT_CHAPTERS}
                            pictures = {ABOUT_PICTURES}></Chapters>
        }
        else if (this.props.screen_name === "Play"){
            return <Play grid_size={this.props.grid_size} socket = {this.props.socket}></Play>
        }
        else {
            return <div className='screen'>Error</div>
        }

    }
}
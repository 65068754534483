import { PieceType, TeamType, Piece, AXIS } from "../Constants/constants"
import { isPawnNormalMove, isPawnStartingMove} from "./pawnLogic"
import { isLegalBishopMove, isLegalRookMove, isLegalQueenMove, isLegalKingMove, isLegalNightMove} from "./pieceLogic"
import { executeMove } from './executeMove';
import { fullReduction } from './stateReducer';
import { promotion_array } from "./sorter";
import { has_king , king_can_be_captured} from "./game_over";
import SuperPiece from "../Constants/superPiece";


type result = {
    possible_pieces : PieceType [],
    message : string
}

export function getPossiblePieces(px : number, py : number, x : number, y : number, piece : SuperPiece, 
                boardState : Piece [],  activePlayer : TeamType, captured : Piece | null, user_team : TeamType) : result {
        const possible_pieces : PieceType [] = [];  
        let message = ''
        // Only the active player can move
        if (piece.team !== activePlayer){
            console.log('Not The Active Player')
            return {possible_pieces, message : 'Not that players turn.'}
        }
        // A non-move is not a move
        if (px === x && py === y){
            //console.log('A non move')
            return {possible_pieces : possible_pieces, message : ''}
        }
        // A move off the board is not a move
        if (!(AXIS.includes(px) &&  AXIS.includes(py) &&  AXIS.includes(x) && AXIS.includes(y))){
            //console.log('move did not land on board')
            return {possible_pieces : possible_pieces, message : ''}
        }
        if (captured) {
            //console.log('waiting for a captured piece to be selected')
            return {possible_pieces : possible_pieces, message : 'Must choose what piece was taken.  Look at the sideboard.'}
        }
        const dx = x - px
        const dy = y - py
        const team = piece.team
        piece.subPieces.forEach(p =>  {
            switch(p){
                case PieceType.PAWN : {
                    if (isPawnStartingMove(px, py, x, y, team, boardState, user_team)){
                        possible_pieces.push(PieceType.PAWN)
                    }
                    else if (isPawnNormalMove(px, py, x, y, team, boardState, user_team)){                          
                            possible_pieces.push(PieceType.PAWN)
                    }
                    break;
                }
                case PieceType.NIGHT : {
                    if (isLegalNightMove(px, py, dx, dy, team, boardState)){
                        possible_pieces.push(PieceType.NIGHT)
                    }
                    break;
                }
                case PieceType.BISHOP : {
                    if (isLegalBishopMove(px, py, dx, dy, team, boardState)){
                        possible_pieces.push(PieceType.BISHOP)
                    }
                    break;
                }
                case PieceType.ROOK : {
                    if (isLegalRookMove(px, py, dx, dy, team, boardState)){
                        possible_pieces.push(PieceType.ROOK)
                    }
                    break;
                }
                case PieceType.QUEEN : {
                    if (isLegalQueenMove(px, py, dx, dy, team, boardState)){
                        possible_pieces.push(PieceType.QUEEN)
                    }
                    break;
                }
                case PieceType.KING : {
                    if (isLegalKingMove(px, py, dx, dy, team, boardState)){
                        possible_pieces.push(PieceType.KING)
                    }
                    break;
                }

            }
        })
    if (possible_pieces.length === 0){
        message = 'No sub piece could move here'
    }
    return {possible_pieces, message}
    }


export function possible_pieces_with_king(px : number, py : number, x : number, y : number, piece : SuperPiece, 
    boardState : Piece [],  activePlayer : TeamType, captured : Piece | null, captured_white_pieces : (string | null)[], 
    captured_black_pieces : (string | null)[], white_promoted : number, black_promoted : number, user_team : TeamType) {
        const result = getPossiblePieces(px, py, x, y, piece, boardState,  activePlayer, captured, user_team)
        const possible_pieces = result.possible_pieces
        const message = result.message 
        const new_state = executeMove(px, py, x, y, possible_pieces, activePlayer, boardState)
        const promotions = promotion_array(new_state.promoted, white_promoted, black_promoted, activePlayer) 
        const reduced_board_state = fullReduction(new_state.board_state, captured_black_pieces, captured_white_pieces, 
                                                    activePlayer, promotions[0], promotions[1], true, user_team, false)
        const king_info = has_king(reduced_board_state, activePlayer)
        if (!king_info.has_king){
            return  {possible_pieces : [], message : 'This move would leave you with no possible Kings.'}
        } else if (king_info.has_king && king_info.index !== -1){
            if (king_can_be_captured(reduced_board_state, king_info.index, user_team)){
                return {possible_pieces : [], message : 'A collapsed King can be captured next turn'}
            } else {
                return {possible_pieces, message} 
            }
        }
        else {
            return {possible_pieces, message}
        }
    }
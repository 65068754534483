import { Component } from 'react';
import { Socket } from 'socket.io-client';
import Button from '../../../Components/Button/Button';
import Toggle from '../../../Components/Toggle/Toggle';
import Select , { SingleValue, StylesConfig } from 'react-select';
import {play} from '../../../Constants/buttonStyles'
import './QueueSelector.css'
import { queue_selector_style } from '../../../Constants/dropdownStyles';
import QueueButton from '../QueueButton/QueueButton'
import { MatchMaker } from '../../../Listeners/MatchMaker'
import {option} from '../../../Constants/constants'


type State = {
    game_options : option[],
    time_options : option[],
    time_control : string | null,
    game_type : string | null,
    en_passant : boolean,
    castling : boolean,
    random_capture : boolean,
    decoherence : boolean,
    play_button? : JSX.Element,
    game_ready : boolean | null,
    room_name? : number,
    user_team? : string
}

type Props = {
    socket : Socket
    grid_size : number
    callback: any
}


export default class QueueSelector extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {
            game_options : [
                { value: 'Solo', label: 'Solo (Local)' },
                { value: 'Versus', label: 'Versus (Online)' },
              ],
              time_options : [
                { value: '5 0 5', label: '5 | 5' },
                { value: '3 0 3', label: '3 | 3' },
                { value: '10 0 5', label: '10 | 5' },
              ],
            game_type : null,
            time_control : null,
            en_passant : false,
            castling : false,
            random_capture : false,
            decoherence : false,
            game_ready : null,
        }
        this.set_en_passant.bind(this)
        this.set_castling.bind(this)
        this.set_decoherence.bind(this)
        this.set_random_capture.bind(this)
        this.change_game_type.bind(this)
        this.change_time_control.bind(this)
        this.submit_play.bind(this)
        this.ready.bind(this)
    }

    componentDidMount(): void {
      this.setState({
        play_button : this.get_button(this.state.game_ready)
      })
    }

    set_en_passant = (new_value : boolean) => {
      this.setState({
        en_passant : new_value
      })
    }

    set_castling = (new_value : boolean) => {
      this.setState({
        castling : new_value
      })
    }

    set_decoherence = (new_value : boolean) => {
      this.setState({
        decoherence : new_value
      })
    }

    set_random_capture = (new_value : boolean) => {
      this.setState({
        random_capture : new_value
      })
    }

    draw_button(game_ready : boolean | null){
      console.log(game_ready)
      this.setState({
        play_button : this.get_button(game_ready)
      })
    }

    get_button(game_ready : boolean | null){
      if (game_ready === null){
        return  <Button name = 'Play' handle_click = {this.submit_play} key={1} style = {play} class_name = 'submit'/>
      } 
      else if (game_ready === false)
        return <div className='waiting'>
                  <div className='waiting-text' style = {{fontSize : this.props.grid_size/6}}>Waiting for opponent . . . </div>
                </div>
      else 
        return <div></div>
    }


    ready = (response : any) => {
      this.setState({
        game_ready : true
      }, () => this.props.callback(response))
    }

    submit_play = () =>{
    if (this.state.time_control !== null && this.state.game_type !== null){
          this.props.socket.emit("join_room", this.state, (response : any) => {
            if (response.user_team !== null){ 
              this.setState({
                game_ready : response.game_ready,
                user_team : response.user_team,
                room_name : response.room_name,
                play_button : this.get_button(response.game_ready)
              }, () => {
                if (response.game_ready) {
                  this.ready(response)
                }
              })
            }
          })
      }
    }

    change_game_type = (e : SingleValue<option>) => {
        if (e !== null){
            this.setState({
                game_type : e.value
            })
        }
    }
    
    change_time_control = (e : SingleValue<option>) => {
      if (e !== null){
          this.setState({
              time_control : e.value
          })
      }
    }

    render() {
        return <div className='queue-sideboard' style = {{fontSize : this.props.grid_size/5}}>
                    <Select styles = {queue_selector_style(this.props.grid_size/2.5)} options={this.state.game_options} className = 'game-type' 
                            onChange={(e) => this.change_game_type(e)}
                            placeholder='Game Mode ...'
                            />
                    <Select styles = {queue_selector_style(this.props.grid_size/2.5)} options={this.state.time_options} className = 'time-control' 
                            onChange={(e) => this.change_time_control(e)}
                            placeholder='Time Control ...'/>
                    <div className = 'toggle-box'>
                      <Toggle callback={this.set_en_passant} label = 'En Passant' class_name='en-passant-toggle' init_value = {false} grid_size = {this.props.grid_size}/>
                      <Toggle callback={this.set_castling} label = 'Castling' class_name='castling-toggle' init_value = {false} grid_size = {this.props.grid_size}/>
                      <Toggle callback={this.set_decoherence} label = 'Decoherence' class_name='decoherence-toggle' init_value = {false} grid_size = {this.props.grid_size}/>
                      <Toggle callback={this.set_random_capture} label = 'Random Capture' class_name='random-capture-toggle' init_value = {false} grid_size = {this.props.grid_size}/>
                    </div>
                    <div className='submit'>
                      <QueueButton 
                          socket = {this.props.socket} 
                          grid_size = {this.props.grid_size} 
                          game_ready = {this.state.game_ready} 
                          callback = {this.submit_play}/>
                    </div>
                    <MatchMaker socket = {this.props.socket} callback = {this.ready}></MatchMaker>
                </div>

    }
}
import { useEffect , useRef} from "react"
import { Socket } from "socket.io-client"



type MoveListenerProps = {
  socket: Socket;
  move_call_back : ( data : any) => void;
  take_call_back : ( data : any) => void;
  dc_callback : (data : any) => void;
  game_over_callback : (data : any) => void;
}; 




export const MoveListener = ({ socket , move_call_back, take_call_back, dc_callback, game_over_callback} : MoveListenerProps) => {
  useEffect(() => {
    socket.on("recieve_move", (data) => {
      move_call_back(data)
    })
    socket.on("recieve_take", (data) => {
      take_call_back(data)
    });
    socket.on("game_over_by_disconnect", (data) => {
      dc_callback(data)
    });
    socket.on("game_over", (data) => {
      game_over_callback(data)
    });
  }, [])
  return null // component does not render anything
}


import { Component } from 'react';
import { GameType, initializeBoardState, TeamType , Piece, AXIS} from '../../../Constants/constants'
import { Socket } from "socket.io-client"
import Tile from '../../../Components/Tile/tile'
import QueueSelector from '../QueueSelector/QueueSelector'
import Preload from '../../../Components/Preload/Preload';
import './PlayLobby.css'
import { STARTING_PICTURES, ALL_PICTURES } from '../../../Constants/Pictures'



type State = {
    board : JSX.Element[],
    pieces : Piece[][],
    skin : string
}

type Props = {
    grid_size : number,
    socket : Socket,
    callback : (board_props : any) => void
}


export default class PlayLobby extends Component<Props, State> {
    constructor(props : Props) { 
        super(props)
        this.state = {
            board: [],
            pieces: initializeBoardState(TeamType.WHITE),
            skin : 'default'
        }
    }
    componentDidMount()
    {
        console.log("here")
        this.draw_board()
    }

    draw_board() {
        this.state.pieces[0].forEach(p => {p.SuperPiece.setURL(this.state.skin, TeamType.WHITE)})
        this.setState({
            board : this.get_board(this.state.pieces[0])
      });
    }

    get_board(pieces : Piece []){
        let board = []
        for (let j = AXIS.length - 1; j >= 0; j-- ){
            for (let i = 0; i < AXIS.length; i++ ) {
                const number = i + j;
                const piece = pieces.find(p => p.x === i && p.y === j) 
                let image = piece ? piece.SuperPiece.img_url : undefined
                board.push(<Tile key = {`${i}, ${j}`} image = {image} x = {i} y = {j} grid_size = {this.props.grid_size}
                            last_square = {undefined} new_square = {undefined} piece = {piece}/>);
            }
        }
        return board
    }

    

    render() {
        return <div className='board-and-sideboard'>
                    <Preload loaded_jsx = {this.state.board} layout = 'chessboard' images = {STARTING_PICTURES} key = '0'/>
                    <Preload loaded_jsx = {[<QueueSelector socket = {this.props.socket} grid_size = {this.props.grid_size} callback = {this.props.callback} key = '2'></QueueSelector>]}
                            layout = 'queue-sideboard-wrap' images = {ALL_PICTURES} key = '1'/>
                    
            </div>
    }
}
import './PieceButton.css'
import  { PieceType } from '../../Constants/constants'

interface Props {
    image_url : string;
    num : number;
    p : PieceType;
    pass_back : (p : PieceType) => void
}



export default function PeiceButton({image_url, num, p, pass_back}: Props){
            return  <div 
                    style = {{backgroundImage : `url(${image_url})`,
                            backgroundRepeat : 'no-repeat', 
                            gridColumnStart : `${num}`, 
                            gridColumnEnd : `${num + 1}`}}
                    className = 'piece-button' onClick = {() => pass_back(p)}>
                    </div>

}